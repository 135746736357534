import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { hostname } from "../../utils";

export default ({
  open,
  onClose,
  sequence,
  companyId,
  getToken,
}: {
  open: boolean;
  onClose: () => void;
  sequence: any;
  companyId: string;
  getToken: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/sequences/${sequence.id}/members`,
        { companyId: companyId, member: formData },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then(() => {
        setFormData({});
        handleClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h6"}>Add Member: {sequence?.name}</Typography>
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={onChange}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={onChange}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="Email"
            name="email"
            value={formData.email}
            onChange={onChange}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="User Data (string, json)"
            name="userData"
            multiline
            rows={4}
            value={formData.userData}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"text"}>Cancel</Button>
          <LoadingButton loading={loading} variant={"contained"} type="submit">
            Create
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
