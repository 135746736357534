import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ModelSelector from "../../components/ModelSelector";

export default ({
  actionParameters,
  setActionParameters,
  currentPlan,
  headers,
  existingRecord,
  setValidForm,
}: {
  actionParameters: any;
  setActionParameters: (p: any) => void;
  currentPlan: string;
  headers: Array<any>;
  existingRecord: boolean;
  setValidForm: (b: boolean) => void;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setValidForm(actionParameters.goal && actionParameters.model);
  }, [actionParameters]);

  const handleInputChange = (event: any) => {
    const newValue = event.target.value;
    setActionParameters({ ...actionParameters, goal: newValue });
    // Check for "/" and the cursor position

    if (
      inputRef.current &&
      newValue[inputRef.current.selectionStart - 1] === "/"
    ) {
      setAnchorEl(inputRef.current);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  return (
    <div>
      <div style={{ marginTop: "16px", display: existingRecord ? "none" : "" }}>
        <Typography variant="subtitle1">Agent Action</Typography>
        <Typography variant="body2">
          Choose your agent action. Do you want to fill in information for
          existing rows or add new rows?
        </Typography>
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel>Action</InputLabel>
          <Select
            label="Action"
            value={actionParameters.action}
            onChange={(e) =>
              setActionParameters({
                ...actionParameters,
                action: e.target.value,
              })
            }
          >
            <MenuItem value="update_rows">Update Rows</MenuItem>
            <MenuItem value="add_rows">Add Rows</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: "16px" }}>
        <Typography variant="subtitle1">Model</Typography>
        <Typography variant="body2">
          Choose your model. Some models are more expensive but provide higher
          quality results.
        </Typography>
        <ModelSelector
          currentPlan={currentPlan}
          onChange={(e) =>
            setActionParameters({ ...actionParameters, model: e.target.value })
          }
          value={actionParameters.model}
          tools
        />
      </div>

      <div style={{ marginTop: "24px" }}>
        <Typography variant="subtitle1">Your Goal</Typography>
        <Typography variant="body2">
          Divide the task into smaller steps. Give clear instructions for each
          step, specifying the expected output.
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={12}
          placeholder="Navigate to {{Company Website}} 'Careers' page. Check if they're hiring SDRs or Account\nExecutives. If so, summarize the top three responsibilities in 20 words or less."
          variant="outlined"
          value={actionParameters.goal}
          onChange={handleInputChange}
          inputRef={inputRef}
          style={{ marginTop: "8px" }}
        />
        <Menu
          anchorEl={anchorEl}
          open={showDropdown}
          onClose={() => setShowDropdown(false)}
          sx={{ maxHeight: 300 }}
        >
          <MenuItem
            onClick={() => {
              setActionParameters({
                ...actionParameters,
                goal:
                  actionParameters.goal.substring(
                    0,
                    actionParameters.goal.length - 1
                  ) + `{{Company Description}}`,
              });
              setShowDropdown(false);
            }}
          >
            Company Description
          </MenuItem>
          {actionParameters.action === "update_rows" && (
            <MenuItem
              onClick={() => {
                setActionParameters({
                  ...actionParameters,
                  goal:
                    actionParameters.goal.substring(
                      0,
                      actionParameters.goal.length - 1
                    ) + `{{Full Record}}`,
                });
                setShowDropdown(false);
              }}
            >
              Full Record
            </MenuItem>
          )}
          {actionParameters.action === "update_rows" &&
            headers.map((header, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  setActionParameters({
                    ...actionParameters,
                    goal:
                      actionParameters.goal.substring(
                        0,
                        actionParameters.goal.length - 1
                      ) + `{{${header.field}}}`,
                  });
                  setShowDropdown(false);
                }}
              >
                {header.field}
              </MenuItem>
            ))}
        </Menu>
        <Typography variant="caption" style={{ marginTop: "4px" }}>
          Type{" "}
          <span
            style={{
              backgroundColor: "lightgray",
              padding: "2px 4px",
              borderRadius: "4px",
              color: "black",
            }}
          >
            /
          </span>{" "}
          to Insert Row information or your Company Description
        </Typography>
      </div>
    </div>
  );
};
