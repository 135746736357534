import {
  CheckCircleOutline,
  Close,
  DeleteOutline,
  MoreVert,
  RadioButtonUnchecked,
  RefreshOutlined,
  ThumbUpAlt,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatStep, hostname, trimmedString } from "../../utils";
import SequenceExecutionEditor from "./SequenceExecutionEditor";
import SequenceExecutionPreview from "./SequenceExecutionPreview";

export default ({
  open,
  onClose,
  companyId,
  sequence,
  getToken,
}: {
  open: boolean;
  onClose: () => void;
  companyId: string;
  sequence?: any;
  getToken: Function;
}) => {
  const [executions, setExecutions] = useState<Array<any>>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedExecution, setSelectedExecution] = useState<any>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const refreshExecutions = async () => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/sequences/${sequence.id}/executions`, {
        params: { companyId, page: page + 1, pageSize },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setExecutions(res.data.items);
        setTotal(res.data.total);
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = executions.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelect = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleBulkApprove = async () => {
    const currentToken = await getToken();
    try {
      await axios.post(
        `${hostname}/sequences/${sequence.id}/executions/approve`,
        { executionIds: selected, companyId },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );
      await refreshExecutions();
      setSelected([]);
    } catch (error) {
      console.error("Error approving executions:", error);
    }
  };

  const handleBulkDelete = async () => {
    const currentToken = await getToken();
    try {
      await axios.post(
        `${hostname}/sequences/${sequence.id}/executions/delete`,
        { companyId, executionIds: selected },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );
      await refreshExecutions();
      setSelected([]);
    } catch (error) {
      console.error("Error deleting executions:", error);
    }
  };

  const deleteExecution = async (execution: any) => {
    const currentToken = await getToken();
    try {
      await axios.post(
        `${hostname}/sequences/${sequence.id}/executions/delete`,
        { companyId, executionIds: [execution.id] },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );
      await refreshExecutions();
      setSelected([]);
    } catch (error) {
      console.error("Error deleting executions:", error);
    }
  };

  const loadExecutions = async () => {
    setLoading(true);
    await refreshExecutions();
    setLoading(false);
  };

  useEffect(() => {
    if (sequence) {
      loadExecutions();
    }
  }, [sequence]);

  const BoolIcon = ({ bool }: { bool: boolean }) => {
    if (bool) {
      return <CheckCircleOutline />;
    }
    return <RadioButtonUnchecked />;
  };

  const isItemSelected = (id: string) => selected.indexOf(id) !== -1;

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h6"}>Outbox: {sequence?.name}</Typography>
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
          {refreshing && <LinearProgress />}
          {!refreshing && <div style={{ height: 4 }}></div>}
          <Button
            sx={{ display: selected.length > 0 ? "" : "none", ml: 1 }}
            startIcon={<ThumbUpAlt />}
            onClick={handleBulkApprove}
            color="primary"
          >
            Approve Selected
          </Button>
          <Button
            sx={{ display: selected.length > 0 ? "" : "none", ml: 1 }}
            startIcon={<DeleteOutline />}
            onClick={handleBulkDelete}
            color="error"
          >
            Delete Selected
          </Button>
          <IconButton
            onClick={() => refreshExecutions()}
            sx={{ float: "right", mr: 1 }}
          >
            <RefreshOutlined />
          </IconButton>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < executions.length
                      }
                      checked={
                        executions.length > 0 &&
                        selected.length === executions.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Step</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Content</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Opens</TableCell>
                  <TableCell>Clicks</TableCell>
                  <TableCell>Sent</TableCell>
                  <TableCell>Approved</TableCell>
                  <TableCell>Send Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {executions.map((execution) => (
                  <TableRow
                    hover
                    onClick={() => handleSelect(execution.id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={execution.id}
                    selected={isItemSelected(execution.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected(execution.id)} />
                    </TableCell>
                    <TableCell>{execution.member.email}</TableCell>
                    <TableCell>{formatStep(execution.step)}</TableCell>
                    <TableCell>{execution.subject}</TableCell>
                    <TableCell>
                      {trimmedString(execution.content, 110)}
                    </TableCell>
                    <TableCell>
                      {execution.created_date
                        ? moment(execution.created_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>{execution.openCount || 0}</TableCell>
                    <TableCell>{execution.clickCount || 0}</TableCell>
                    <TableCell>
                      <BoolIcon bool={execution.executed} />
                    </TableCell>
                    <TableCell>
                      <BoolIcon bool={execution.approved} />
                    </TableCell>
                    <TableCell>
                      {execution.sentDate
                        ? moment(execution.sentDate).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={anchorEl !== null}
                        onClose={(e: any) => {
                          e.stopPropagation();
                          setAnchorEl(null);
                        }}
                      >
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedExecution(execution);
                            setPreviewOpen(true);
                            setAnchorEl(null);
                          }}
                        >
                          Preview
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedExecution(execution);
                            setEditorOpen(true);
                            setAnchorEl(null);
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteExecution(execution);
                            setAnchorEl(null);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={total}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </DialogContent>
      <SequenceExecutionEditor
        open={editorOpen}
        onClose={() => {
          setEditorOpen(false);
          refreshExecutions();
        }}
        execution={selectedExecution}
        companyId={companyId}
        getToken={getToken}
      />
      <SequenceExecutionPreview
        open={previewOpen}
        onClose={() => {
          setPreviewOpen(false);
          refreshExecutions();
        }}
        execution={selectedExecution}
        companyId={companyId}
        getToken={getToken}
      />
    </Dialog>
  );
};
