import { Description, Sell, Source } from "@mui/icons-material";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import React from "react";
import IconMenuItem from "../../components/IconMenuItem";
import ModelSelector from "../../components/ModelSelector";

export default ({
  open,
  onClose,
  contextSettings,
  setContextSettings,
  strategyOptions,
  documents,
  onModelChange,
  saveCompanyDefaultSettings,
  saveSettings,
  handleDocumentChange,
  currentPlan,
  company,
}: {
  open: boolean;
  onClose: () => void;
  contextSettings: any;
  setContextSettings: Function;
  strategyOptions: any;
  documents: Array<any>;
  onModelChange: (e: any) => void;
  saveCompanyDefaultSettings: (e: any) => void;
  saveSettings: (e: any) => void;
  handleDocumentChange: (e: any) => void;
  currentPlan: any;
  company: any;
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <DialogContentText>External Sources</DialogContentText>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => {
                setContextSettings({
                  ...contextSettings,
                  // @ts-ignore
                  documents: e.target.checked,
                });
              }}
              checked={contextSettings.documents}
            />
          }
          label="Knowledge Base"
        />
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => {
                setContextSettings({
                  ...contextSettings,
                  // @ts-ignore
                  searchEngine: e.target.checked,
                });
              }}
              checked={contextSettings.searchEngine}
            />
          }
          label="Search Engine Results"
        />
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => {
                setContextSettings({
                  ...contextSettings,
                  // @ts-ignore
                  websitesJs: e.target.checked,
                });
              }}
              checked={contextSettings.websitesJs}
            />
          }
          label="Websites"
        />
        <DialogContentText style={{ marginTop: 10 }}>
          Memory Recall
        </DialogContentText>
        <FormControlLabel
          control={
            <Switch
              onChange={(e: any) => {
                setContextSettings({
                  ...contextSettings,
                  longTermMemories: e.target.checked,
                });
              }}
              checked={contextSettings.longTermMemories}
            />
          }
          label="Long-Term Memories"
        />
        <FormControlLabel
          control={
            <Switch
              onChange={(e: any) => {
                setContextSettings({
                  ...contextSettings,
                  shortTermMemories: e.target.checked,
                });
              }}
              checked={contextSettings.shortTermMemories}
            />
          }
          label="Short-Term Memories"
        />
        <DialogContentText style={{ marginTop: 10 }}>
          System Persona
        </DialogContentText>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => {
                setContextSettings({
                  ...contextSettings,
                  // @ts-ignore
                  employee: e.target.checked,
                });
              }}
              checked={contextSettings.employee}
            />
          }
          label="Employee Context"
        />
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => {
                setContextSettings({
                  ...contextSettings,
                  // @ts-ignore
                  company: e.target.checked,
                });
              }}
              checked={contextSettings.company}
            />
          }
          label="Company Context"
        />
        <ModelSelector
          tools={false}
          currentPlan={currentPlan}
          value={contextSettings.model}
          onChange={onModelChange}
        />
        {contextSettings.longTermMemories && (
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>Long-Term Memory Scope</InputLabel>
            <Select
              label="Long-Term Memory Scope"
              value={contextSettings.memoryScope}
              onChange={(e: any) =>
                setContextSettings({
                  ...contextSettings,
                  memoryScope: e.target.value,
                })
              }
            >
              <MenuItem value="chat">Current Chat</MenuItem>
              <MenuItem value="all">All Chats</MenuItem>
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel>Strategy</InputLabel>
          <Select
            label="Strategy"
            value={contextSettings.strategy}
            onChange={(e: any) =>
              setContextSettings({
                ...contextSettings,
                strategy: e.target.value,
              })
            }
          >
            {strategyOptions.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {contextSettings.documents && (
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>Document Scope</InputLabel>
            <Select
              value={contextSettings.documentScope}
              label="Knowledge Base Scope"
              onChange={handleDocumentChange}
            >
              <MenuItem key={"tags"} value={"tags"}>
                <IconMenuItem name={"Document Tags"} icon={<Sell />} />
              </MenuItem>
              <MenuItem key={"root"} value={"root"}>
                <IconMenuItem name={"/"} icon={<Source />} />
              </MenuItem>
              <MenuItem key={"shared"} value={"/shared"}>
                <IconMenuItem
                  name={company.name + " Shared"}
                  icon={<Source color="primary" />}
                />
              </MenuItem>
              {documents.map((doc) => (
                <MenuItem key={doc.id} value={doc.id}>
                  <IconMenuItem
                    name={doc.name}
                    icon={doc.type === "folder" ? <Source /> : <Description />}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {contextSettings.documents && (
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>Document Strategy</InputLabel>
            <Select
              value={contextSettings.documentStrategy}
              label="Document Strategy"
              onChange={(e) =>
                setContextSettings({
                  ...contextSettings,
                  documentStrategy: e.target.value,
                })
              }
            >
              <MenuItem value="similarity-ranking">Similarity Ranking</MenuItem>
              <MenuItem value="full-documents">
                Full Documents
                <Chip label="+5 Credits" style={{ marginLeft: 5 }} />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={saveCompanyDefaultSettings}>Save As Default</Button>
        <Button onClick={saveSettings} variant="outlined">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
