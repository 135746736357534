import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import capture, { identifyUserCompany } from "../../capture";
import LoadingButton from "../../components/LoadingButton";
import { COMPANY_SIZES, INDUSTRIES } from "../../constants";
import { Company } from "../../types";
import { hostname, parseError, uniqueId, validateURL } from "../../utils";

const emptyCompany = (): Company => {
  return {
    uid: "",
    id: uniqueId(),
    name: "",
    type: "",
    description: "",
    website: "",
    industry: "",
    size: "",
    location: "",
    employees: [],
  };
};

export default ({
  open,
  onClose,
  onSuccess,
  selectedCompany,
  chatApi,
  eventListener,
  currentUser,
  getToken,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  selectedCompany: Company | null;
  chatApi: any;
  eventListener: Function;
  currentUser: User | undefined;
  getToken: Function;
}) => {
  const [newCompany, setNewCompany] = useState<Company>(emptyCompany());
  const [modalLoading, setModalLoading] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [descLoading, setDescLoading] = useState(false);
  const [websiteError, setWebsiteError] = useState("");

  const createCompany = async (retry: number | undefined = 0) => {
    if (!validForm) return;
    setModalLoading(true);
    try {
      await saveCompany(newCompany);
      identifyUserCompany(currentUser, newCompany);
      capture("Create company", {});
      await chatApi.createEmployees(
        newCompany,
        9,
        1,
        null,
        (newStatus: string) => setStatusText(newStatus)
      );
    } catch {
      capture("Create company failed", newCompany);
      if (retry < 3) {
        createCompany(retry + 1);
      }
    }
    capture("Create company sucess", {});
    handleClose();
  };

  const validForm = () => {
    return newCompany.name && newCompany.type && newCompany.website;
  };

  const generateDescription = async () => {
    setDescLoading(true);
    try {
      const prompt = `
      Please robust company description in under 800 words for the following company. If available, read the company
      website to get specific details about the company.

      Name: ${newCompany.name}
      Type: ${newCompany.type}
      Industry: ${newCompany.industry}
      Website: ${newCompany.website}
      `;
      const description = await chatApi.syncCompletion(prompt, true);
      setNewCompany({ ...newCompany, description: description });
    } catch (err) {
      eventListener({
        type: "SET_ERROR",
        error: parseError(err),
      });
    }
    setDescLoading(false);
  };

  const saveCompany = async (company: Company) => {
    const currentToken = await getToken();
    await axios
      .post(`${hostname}/companies`, company, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const editCompany = async () => {
    setModalLoading(true);
    await saveCompany(newCompany);
    setModalLoading(false);
    handleClose();
  };

  const handleWebsiteChange = (e: any) => {
    const website = e.target.value;
    if (validateURL(website)) {
      setWebsiteError("");
    } else {
      setWebsiteError(
        "Invalid URL format. Make sure it includes the protocol (http/https)."
      );
    }
    setNewCompany({ ...newCompany, website });
  };

  const handleClose = () => {
    setNewCompany(emptyCompany());
    setModalLoading(false);
    setStatusText("");
    onClose();
  };

  useEffect(() => {
    if (selectedCompany) {
      setNewCompany(selectedCompany);
    }
  }, [selectedCompany]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent>
        {!selectedCompany && (
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create Company
            </Typography>
            <Typography variant="body1" style={{ marginTop: 10 }}>
              Provide a name, type and description for the new company. This
              information will be used to generate your AI employees and train
              them for company relevant answers.
            </Typography>
          </div>
        )}
        {selectedCompany && (
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginTop: 10 }}
          >
            Edit Company
          </Typography>
        )}
        <TextField
          style={{ marginTop: 20 }}
          fullWidth
          value={newCompany.name}
          label="Name"
          required
          onChange={(e) =>
            setNewCompany({ ...newCompany, name: e.target.value })
          }
        />
        <TextField
          style={{ marginTop: 20 }}
          value={newCompany.type}
          fullWidth
          required
          onChange={(e) =>
            setNewCompany({ ...newCompany, type: e.target.value })
          }
          label="Company Type"
        />
        <TextField
          select
          style={{ marginTop: 20 }}
          fullWidth
          value={newCompany.industry}
          label="Industry"
          required
          onChange={(e) =>
            setNewCompany({ ...newCompany, industry: e.target.value })
          }
        >
          {INDUSTRIES.map((industry) => (
            <MenuItem key={industry} value={industry}>
              {industry}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          style={{ marginTop: 20 }}
          value={newCompany.website}
          fullWidth
          onChange={handleWebsiteChange}
          label="Website"
          error={!!websiteError}
          placeholder="https://www.example.com"
          helperText={websiteError}
          required
        />
        <TextField
          select
          style={{ marginTop: 20 }}
          fullWidth
          value={newCompany.size}
          label="Company Size"
          placeholder="1-10"
          required
          onChange={(e) =>
            setNewCompany({ ...newCompany, size: e.target.value })
          }
        >
          {COMPANY_SIZES.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          style={{ marginTop: 20 }}
          fullWidth
          value={newCompany.location}
          label="Location"
          onChange={(e) =>
            setNewCompany({ ...newCompany, location: e.target.value })
          }
          placeholder="City, Country"
          required
        />
        <TextField
          sx={{ mt: 2 }}
          value={newCompany.description}
          minRows={5}
          multiline
          fullWidth
          onChange={(e) =>
            setNewCompany({ ...newCompany, description: e.target.value })
          }
          label="Description"
          required
        />
        {!selectedCompany && (
          <LoadingButton
            loading={descLoading}
            style={{ marginTop: 5 }}
            onClick={() => generateDescription()}
          >
            Generate Description
          </LoadingButton>
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ marginTop: 20, marginBottom: 20 }}>{statusText}</div>
        {!selectedCompany && (
          <LoadingButton
            loading={modalLoading}
            variant="outlined"
            style={{ marginTop: 10 }}
            disabled={!validForm()}
            onClick={() => createCompany()}
          >
            Create
          </LoadingButton>
        )}
        {selectedCompany && (
          <LoadingButton
            loading={modalLoading}
            variant="outlined"
            style={{ marginTop: 10 }}
            disabled={!validForm()}
            onClick={() => editCompany()}
          >
            Save
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
