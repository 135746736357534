import axios from "axios";
import { User } from "firebase/auth";
import { Company } from "./types";

export default function (name: string, data: any) {
  // @ts-ignore
  if (window.posthog) {
    // @ts-ignore
    window.posthog.capture(name, data);
  }
}

export function identify(user: User) {
  // @ts-ignore
  if (window.posthog) {
    var firstName = "";
    var lastName = "";
    var nameParts = user.displayName?.split(" ");
    if (nameParts) {
      if (nameParts.length > 0) {
        firstName = nameParts[0];
      }
      if (nameParts.length > 1) {
        lastName = nameParts[1];
      }
    }
    var distictId = "";
    try {
      // @ts-ignore
      distictId = window.posthog.get_distinct_id();
    } catch {
      distictId = "";
    }
    if (distictId === user.email) {
      return;
    }

    // @ts-ignore
    window.posthog.identify(user.email, {
      email: user.email,
      displayName: user.displayName,
      firstName: firstName,
      lastName: lastName,
      uid: user.uid,
    });
  }
}

export function identifyUserCompany(user: User | undefined, company: Company) {
  try {
    if (!user) return;
    var firstName = "";
    var lastName = "";
    var nameParts = user.displayName?.split(" ");
    if (nameParts) {
      if (nameParts.length > 0) {
        firstName = nameParts[0];
      }
      if (nameParts.length > 1) {
        lastName = nameParts[1];
      }
    }
    axios.post(
      "https://api.parallellabs.app/lists/00c58cf6-7874-4ae8-a55b-b4ff53ff1fc0/webhook?createColumns=1&matchFields=Email",
      {
        "First Name": firstName,
        "Last Name": lastName,
        Email: user.email,
        "Phone Number": user.phoneNumber,
        "Photo URL": user.photoURL,
        "Company Name": company.name,
        "Company Description": company.description,
        "Company Website": company.website,
        "Company Headquarters": company.location,
        "Company Employee Range": company.size,
        "Company Type": company.type,
        "Company Industry": company.industry,
      }
    );
  } catch (err) {
    console.log(err);
  }
}
