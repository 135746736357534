import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

export default ({
  open,
  onClose,
  message,
}: {
  open: boolean;
  onClose: () => void;
  message: any;
}) => {
  if (!message) return <></>;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">
            {message.senderName} {message.sender}
          </Typography>
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
        {message.subject}
      </DialogTitle>
      <DialogContent>
        <Box dangerouslySetInnerHTML={{ __html: message.body }}></Box>
      </DialogContent>
    </Dialog>
  );
};
