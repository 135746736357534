import { Close, GroupOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import RswEditor, { EditorProvider } from "react-simple-wysiwyg";
import LoadingButton from "../../components/LoadingButton";
import SelectEmployeeDialog from "../../components/SelectEmployee";
import { Company, Employee } from "../../types";
import { hostname } from "../../utils";

export default ({
  open,
  onClose,
  profile,
  companyId,
  eventListener,
  getToken,
  employees,
  company,
}: {
  open: boolean;
  onClose: () => void;
  profile: any;
  companyId: string;
  eventListener: Function;
  getToken: Function;
  employees: Employee[];
  company: Company;
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>({
    name: "",
    email: "",
    signature: "",
    inboxId: "",
  });
  const [employeeSelectOpen, setEmployeeSelectOpen] = useState(false);
  const [inboxes, setInboxes] = useState([]);
  const [selectedInbox, setSelectedInbox] = useState<any>(null);

  const handleClose = () => {
    onClose();
  };

  const getInboxes = async () => {
    const currentToken = await getToken();
    axios
      .get(`${hostname}/inboxes`, {
        params: { companyId, page: 1, pageSize: 100 },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setInboxes(res.data.items);
      });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/sequences/profiles`,
        { ...formData, companyId },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then(() => {
        setFormData({});
        handleClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onEmployeeSelect = (employee: Employee) => {
    setEmployeeSelectOpen(false);
    const email =
      employee.name.replace(" ", ".").toLowerCase() +
      "@" +
      selectedInbox.domain;

    var companyWebsiteTag = "";
    if (company.website) {
      companyWebsiteTag = `<a href="${company.website}">${company.website}</a><br>`;
    }
    const signature = `<div>
        <img width="50" height="50" src="${employee.picture}" alt="${employee.name}"/><br>
        <strong>${employee.name}</strong><br>
        ${employee.title}<br>
        ${company.name}<br>
        ${companyWebsiteTag}
        ${email}
    </div>
    `;
    setFormData({
      ...formData,
      employeeId: employee.id,
      name: employee.name,
      email,
      signature,
    });
  };

  useEffect(() => {
    getInboxes();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h6"}>Sender Profile</Typography>
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText>
            Create a sender profile to use in your sequences. First, select the
            inbox you would like incoming mail to be stored in. Then, type out
            your personal signature or select an employee to assign to this
            profile. The employee's name, email, and signature will be used in
            the profile. Click{" "}
            <a href={`/dashboard/companies/${companyId}/inbox`} target="_blank">
              here
            </a>{" "}
            to configure a new inbox.
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Inbox</InputLabel>
            <Select
              value={formData.inboxId}
              label="Inbox"
              onChange={(e: any) => {
                setFormData({ ...formData, inboxId: e.target.value });
                setSelectedInbox(
                  inboxes.find((inbox: any) => inbox.id === e.target.value)
                );
              }}
            >
              {inboxes.map((inbox: any) => (
                <MenuItem key={inbox.id} value={inbox.id}>
                  {inbox.name} ({inbox.domain})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => setEmployeeSelectOpen(true)}
            startIcon={<GroupOutlined />}
            variant={"outlined"}
            disabled={selectedInbox === null}
          >
            Select Employee
          </Button>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="Name"
            name="name"
            value={formData.name}
            onChange={onChange}
            disabled={selectedInbox === null}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="Email"
            name="email"
            value={formData.email}
            onChange={onChange}
            disabled={selectedInbox === null}
          />
          <Box
            sx={{
              mt: 2,
            }}
          >
            <EditorProvider>
              <RswEditor
                style={{ height: 300 }}
                containerProps={{ style: { resize: "vertical" } }}
                value={formData.signature}
                onChange={(e) =>
                  setFormData({ ...formData, signature: e.target.value })
                }
                disabled={selectedInbox === null}
              ></RswEditor>
            </EditorProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant={"text"}>Cancel</Button>
          <LoadingButton
            disabled={selectedInbox === null}
            loading={loading}
            variant={"contained"}
            type="submit"
          >
            Create
          </LoadingButton>
        </DialogActions>
      </form>
      <SelectEmployeeDialog
        open={employeeSelectOpen}
        onClose={() => setEmployeeSelectOpen(false)}
        title={"Select Employee"}
        content={"Select employee to assign to this profile"}
        buttonText="Submit"
        employees={employees}
        onSubmit={onEmployeeSelect}
      />
    </Dialog>
  );
};
