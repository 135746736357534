import { Inbox, MoreVert, RefreshOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import moment from "moment";
import { useEffect, useState } from "react";
import { Company } from "../../types";
import { hostname, parseError } from "../../utils";
import InboxEditorDialog from "./InboxEditorDialog";
import InboxMessagesDialog from "./InboxMessagesDialog";
import InboxWebhookDialog from "./InboxWebhookDialog";

export default ({
  currentUser,
  getToken,
  openDialog,
  eventListener,
  companyId,
  company,
}: {
  getToken: Function;
  openDialog: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
  company: Company;
}) => {
  const [inboxes, setInboxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [selectedInbox, setSelectedInbox] = useState<any>(null);
  const [webhookOpen, setWebhookOpen] = useState(false);
  const [messagesOpen, setMessagesOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteInbox = async (sequenceId: string) => {
    const currentToken = await getToken();
    openDialog(
      "Delete Inbox",
      `Are you sure you want to delete this inbox?`,
      "Confirm",
      () => () => {
        setLoading(true);
        return axios
          .delete(`${hostname}/inboxes/${sequenceId}`, {
            params: { companyId },
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          })
          .finally(() => {
            setLoading(false);
            refreshInboxes();
          });
      }
    );
  };

  const refreshInboxes = async () => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/inboxes`, {
        params: { companyId, page: page + 1, pageSize },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setInboxes(res.data.items);
        setTotal(res.data.total);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const loadInboxes = async () => {
    setLoading(true);
    await refreshInboxes();
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser.uid) {
      refreshInboxes();
    }
  }, [page]);

  useEffect(() => {
    if (currentUser.uid) {
      loadInboxes();
    }
  }, [currentUser.uid, companyId]);

  return (
    <Box>
      <Box>
        <Alert severity="info" sx={{ mt: 2 }}>
          Inboxes is a beta feature with limited functionality at the moment.
          Please contact us if you have any questions or feedback.
        </Alert>
        <Box>
          <Button
            sx={{ mt: 2 }}
            onClick={() => setEditorOpen(true)}
            startIcon={<Inbox />}
          >
            Create Inbox
          </Button>
        </Box>
        <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
          {refreshing && <LinearProgress />}
          {!refreshing && <div style={{ height: 4 }}></div>}
          <IconButton
            onClick={() => refreshInboxes()}
            style={{ float: "right", marginRight: 5 }}
          >
            <RefreshOutlined />
          </IconButton>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Domain</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inboxes.map((inbox: any) => (
                  <TableRow key={inbox.id}>
                    <TableCell>{inbox.name}</TableCell>
                    <TableCell>{inbox.domain}</TableCell>
                    <TableCell>
                      {inbox.created_date
                        ? moment(inbox.created_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {inbox.updated_date
                        ? moment(inbox.updated_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                          setSelectedInbox(inbox);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={anchorEl !== null}
                        onClose={(e: any) => {
                          e.stopPropagation();
                          setAnchorEl(null);
                        }}
                      >
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setMessagesOpen(true);
                            setAnchorEl(null);
                          }}
                        >
                          Messages
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            console.log(inbox);
                            e.stopPropagation();
                            setEditorOpen(true);
                            setAnchorEl(null);
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setWebhookOpen(true);
                            setAnchorEl(null);
                          }}
                        >
                          Webhook
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteInbox(selectedInbox.id);
                            setAnchorEl(null);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={total}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </Box>
      <InboxEditorDialog
        open={editorOpen}
        onClose={() => {
          refreshInboxes();
          setEditorOpen(false);
          setSelectedInbox(null);
        }}
        getToken={getToken}
        eventListener={eventListener}
        companyId={companyId}
        inbox={selectedInbox}
      />
      <InboxWebhookDialog
        open={webhookOpen}
        onClose={() => {
          setWebhookOpen(false);
          setSelectedInbox(null);
        }}
        inbox={selectedInbox}
      />
      <InboxMessagesDialog
        open={messagesOpen}
        onClose={() => {
          setMessagesOpen(false);
          setSelectedInbox(null);
        }}
        inbox={selectedInbox}
        getToken={getToken}
        companyId={companyId}
      />
    </Box>
  );
};
