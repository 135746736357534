import { Firestore } from "@firebase/firestore";
import { Box, LinearProgress, Typography } from "@mui/material";
import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Company, Employee } from "../../types";
import Blogs from "../blog/Blogs";
import ChatLog from "../chat/ChatLog";
import Images from "../images/Images";
import Inboxes from "../inbox/Inboxes";
import Integrations from "../integrations/Integrations";
import Documents from "../knowledge_base/Documents";
import Lists from "../lists/Lists";
import Projects from "../projects/Projects";
import Sequences from "../sequences/Sequences";
import Collaborators from "../settings/Collaborators";
import Workflows from "../workflow/Workflows";
import Employees from "./Employees";

export default ({
  db,
  mode,
  currentUser,
  id,
  token,
  getToken,
  eventListener,
  openDialog,
  subScreenName,
  instanceId,
  company,
  currentPlan,
  employees,
}: {
  db: Firestore;
  mode: string;
  currentUser: any | User;
  id: string;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
  subScreenName: string | undefined;
  instanceId: string | undefined;
  company: Company;
  currentPlan: string;
  employees: Array<Employee | any>;
}) => {
  const [init, setInit] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(subScreenName || "employees");

  useEffect(() => {
    setTab(subScreenName || "employees");
  }, [subScreenName]);

  if (!company) {
    return <LinearProgress />;
  }

  function capitalizeWords(str: string) {
    return str
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <Box sx={{ pt: 2, pl: 4, pr: 4 }}>
      <Typography
        variant="h4"
        style={{
          fontFamily: '"Cairo", sans-serif',
          display: instanceId ? "none" : "",
        }}
      >
        {capitalizeWords(tab)}
      </Typography>
      <div hidden={tab !== "employees"}>
        <Employees
          companyId={id}
          getToken={getToken}
          openDialog={openDialog}
          currentUser={tab === "employees" ? currentUser : {}}
          company={company}
          eventListener={eventListener}
        />
      </div>
      <div hidden={tab !== "history"}>
        <ChatLog
          style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
          companyId={id}
          getToken={getToken}
          currentUser={tab === "history" ? currentUser : {}}
          perPage={30}
          currentChatId={null}
          eventListener={eventListener}
        />
      </div>
      <div hidden={tab !== "documents"}>
        <Documents
          db={db}
          currentUser={tab === "documents" ? currentUser : {}}
          companyId={id}
          token={token}
          getToken={getToken}
          documentId={instanceId}
          eventListener={eventListener}
          openDialog={openDialog}
          setTab={setTab}
          currentPlan={currentPlan}
          company={company}
          employees={employees}
        />
      </div>
      {currentPlan !== "free" && (
        <div hidden={tab !== "projects"}>
          <Projects
            currentUser={tab === "projects" ? currentUser : {}}
            company={company}
            companyId={id}
            getToken={getToken}
            eventListener={eventListener}
          />
        </div>
      )}
      {currentPlan !== "free" && (
        <div hidden={tab !== "images"}>
          <Images
            currentUser={tab === "images" ? currentUser : {}}
            companyId={id}
            getToken={getToken}
            eventListener={eventListener}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "blogs"}>
          <Blogs
            db={db}
            currentUser={tab === "blogs" ? currentUser : {}}
            companyId={id}
            token={token}
            getToken={getToken}
            documentId={instanceId}
            eventListener={eventListener}
            openDialog={openDialog}
            setTab={setTab}
            company={company}
            employees={employees}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "integrations"}>
          <Integrations
            company={company}
            currentUser={tab === "integrations" ? currentUser : {}}
            companyId={id}
            db={db}
            token={token}
            getToken={getToken}
            eventListener={eventListener}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "collaborators"}>
          <Collaborators
            openDialog={openDialog}
            company={company}
            companyId={id}
            getToken={getToken}
            currentUser={tab === "collaborators" ? currentUser : {}}
            eventListener={eventListener}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "workflows"}>
          <Workflows
            mode={mode}
            currentUser={tab === "workflows" ? currentUser : {}}
            company={company}
            companyId={id}
            getToken={getToken}
            eventListener={eventListener}
            currentPlan={currentPlan}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "lists"}>
          <Lists
            mode={mode}
            currentUser={tab === "lists" ? currentUser : {}}
            company={company}
            companyId={id}
            getToken={getToken}
            eventListener={eventListener}
            currentPlan={currentPlan}
            instanceId={instanceId}
            openDialog={openDialog}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "sequences"}>
          <Sequences
            currentUser={tab === "sequences" ? currentUser : {}}
            company={company}
            companyId={id}
            getToken={getToken}
            eventListener={eventListener}
            openDialog={openDialog}
            employees={employees}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "inbox"}>
          <Inboxes
            currentUser={tab === "inbox" ? currentUser : {}}
            company={company}
            companyId={id}
            getToken={getToken}
            eventListener={eventListener}
            openDialog={openDialog}
          />
        </div>
      )}
    </Box>
  );
};
