import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { hostname } from "../../utils";

const KeywordIdeasDialog = ({
  open,
  onClose,
  getToken,
}: {
  open: boolean;
  onClose: () => void;
  getToken: Function;
}) => {
  const [keywords, setKeywords] = useState("");
  const [keywordIdeas, setKeywordIdeas] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      const currentToken = await getToken();
      const response = await axios.post(
        `${hostname}/keywords/ideas`,
        {
          keywords: keywords.split(",").map((k) => k.trim()),
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );

      setKeywordIdeas(response.data.ideas);
    } catch (err) {
      console.error("Error fetching keyword ideas:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Get Keyword Ideas</DialogTitle>

      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Enter keywords separated by commas to get related keyword ideas
          </Typography>

          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            placeholder="e.g. artificial intelligence, machine learning, data science"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            disabled={loading}
          />
        </Box>
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Keyword</TableCell>
                <TableCell>Impressions</TableCell>
                <TableCell>Clicks</TableCell>
                <TableCell>Bid</TableCell>
                <TableCell>CPC</TableCell>
                <TableCell>CTR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keywordIdeas.map((idea, index) => (
                <TableRow>
                  <TableCell>{idea.keyword}</TableCell>
                  <TableCell>{idea.impressions}</TableCell>
                  <TableCell>{idea.clicks}</TableCell>
                  <TableCell>${idea.bid}</TableCell>
                  <TableCell>${idea.cpc}</TableCell>
                  <TableCell>{idea.ctr}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Close
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          variant="contained"
          disabled={!keywords.trim() || loading}
        >
          Get Ideas
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default KeywordIdeasDialog;
