import { Edit, LightbulbOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export default ({
  open,
  onClose,
  onChoose,
}: {
  open: boolean;
  onClose: () => void;
  onChoose: (type: string) => void;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Choose Sequence Type</DialogTitle>
      <DialogContent>
        <Button
          variant="outlined"
          startIcon={<Edit />}
          onClick={() => onChoose("")}
          sx={{ mb: 2 }}
          fullWidth
        >
          Start from scratch
        </Button>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<LightbulbOutlined />}
          onClick={() => onChoose("ai")}
        >
          AI Generated Sequence
        </Button>
      </DialogContent>
    </Dialog>
  );
};
