import {
  ContactMail,
  MoreVert,
  RefreshOutlined,
  Route,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import moment from "moment";
import { useEffect, useState } from "react";
import { Company, Employee } from "../../types";
import { hostname, parseError } from "../../utils";
import GenerateSequenceDialog from "./GenerateSequenceDialog";
import SenderProfiles from "./SenderProfiles";
import SequenceEditor from "./SequenceEditor";
import SequenceExecutionsDialog from "./SequenceExecutionsDialog";
import SequenceMembers from "./SequenceMembers";
import SequenceTypeDialog from "./SequenceTypeDialog";

export default ({
  currentUser,
  getToken,
  openDialog,
  eventListener,
  companyId,
  company,
  employees,
}: {
  getToken: Function;
  openDialog: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
  company: Company;
  employees: Employee[];
}) => {
  const [sequences, setSequences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [membersOpen, setMembersOpen] = useState(false);
  const [executionsOpen, setExecutionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSequence, setSelectedSequence] = useState<any>(null);
  const [sequenceTypeOpen, setSequenceTypeOpen] = useState(false);
  const [generatedSequenceOpen, setGenerateSequenceOpen] = useState(false);
  const [senderProfilesOpen, setSenderProfilesOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState(0);

  const chooseSequenceType = (type: string) => {
    if (type === "ai") {
      setGenerateSequenceOpen(true);
    } else {
      setEditorOpen(true);
    }
    setSequenceTypeOpen(false);
  };

  const onGeneratedSequence = (sequence: any) => {
    setSelectedSequence({ name: sequence.name, steps: sequence.steps });
    setGenerateSequenceOpen(false);
    setEditorOpen(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteSequence = async (sequenceId: string) => {
    const currentToken = await getToken();
    openDialog(
      "Delete Sequence",
      `Are you sure you want to delete this sequence?`,
      "Confirm",
      () => () => {
        setLoading(true);
        return axios
          .delete(`${hostname}/sequences/${sequenceId}`, {
            params: { companyId },
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          })
          .finally(() => {
            setLoading(false);
            refreshSequences();
          });
      }
    );
  };

  const refreshSequences = async () => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/sequences`, {
        params: { companyId, page: page + 1, pageSize },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setSequences(res.data.items);
        setTotal(res.data.total);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const loadSequences = async () => {
    setLoading(true);
    await refreshSequences();
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser.uid) {
      refreshSequences();
    }
  }, [page]);

  useEffect(() => {
    if (currentUser.uid) {
      loadSequences();
    }
  }, [currentUser.uid, companyId]);

  return (
    <Box>
      <Box>
        <Alert severity="info" sx={{ mt: 2 }}>
          Sequences is a beta feature with limited functionality at the moment.
          Please contact us if you have any questions or feedback.
        </Alert>
        <Box>
          <Button
            sx={{ mt: 2 }}
            onClick={() => setSequenceTypeOpen(true)}
            startIcon={<Route />}
          >
            Create Sequence
          </Button>
          <Button
            sx={{ mt: 2, ml: 2 }}
            onClick={() => setSenderProfilesOpen(true)}
            startIcon={<ContactMail />}
          >
            Sender Profiles
          </Button>
        </Box>
        <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
          {refreshing && <LinearProgress />}
          {!refreshing && <div style={{ height: 4 }}></div>}
          <IconButton
            onClick={() => refreshSequences()}
            style={{ float: "right", marginRight: 5 }}
          >
            <RefreshOutlined />
          </IconButton>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell>Members</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sequences.map((sequence: any) => (
                  <TableRow key={sequence.id}>
                    <TableCell>{sequence.name}</TableCell>
                    <TableCell>
                      {sequence.created_date
                        ? moment(sequence.created_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {sequence.updated_date
                        ? moment(sequence.updated_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>{sequence.memberCount}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={anchorEl !== null}
                        onClose={(e: any) => {
                          e.stopPropagation();
                          setAnchorEl(null);
                        }}
                      >
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedSequence(sequence);
                            setEditorOpen(true);
                            setAnchorEl(null);
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedSequence(sequence);
                            setExecutionsOpen(true);
                            setAnchorEl(null);
                          }}
                        >
                          Outbox
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedSequence(sequence);
                            setMembersOpen(true);
                            setAnchorEl(null);
                          }}
                        >
                          Members
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteSequence(sequence.id);
                            setAnchorEl(null);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={total}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </Box>
      <SequenceTypeDialog
        open={sequenceTypeOpen}
        onClose={() => setSequenceTypeOpen(false)}
        onChoose={chooseSequenceType}
      />
      <SequenceEditor
        open={editorOpen}
        sequence={selectedSequence}
        onClose={() => {
          setEditorOpen(false);
          setSelectedSequence(null);
          refreshSequences();
        }}
        companyId={companyId}
        getToken={getToken}
      />
      <GenerateSequenceDialog
        open={generatedSequenceOpen}
        onClose={() => setGenerateSequenceOpen(false)}
        onSequence={onGeneratedSequence}
        companyId={companyId}
        getToken={getToken}
      />
      <SequenceMembers
        open={membersOpen}
        onClose={() => {
          setMembersOpen(false);
          setSelectedSequence(null);
          refreshSequences();
        }}
        companyId={companyId}
        sequence={selectedSequence}
        getToken={getToken}
      />
      <SequenceExecutionsDialog
        open={executionsOpen}
        onClose={() => {
          setExecutionsOpen(false);
          setSelectedSequence(null);
          refreshSequences();
        }}
        companyId={companyId}
        sequence={selectedSequence}
        getToken={getToken}
      />
      <SenderProfiles
        open={senderProfilesOpen}
        onClose={() => {
          setSenderProfilesOpen(false);
          refreshSequences();
        }}
        companyId={companyId}
        eventListener={eventListener}
        getToken={getToken}
        employees={employees}
        company={company}
      />
    </Box>
  );
};
