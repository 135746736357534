import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { hostname } from "../../utils";

export default ({
  open,
  onClose,
  execution,
  getToken,
  companyId,
}: {
  open: boolean;
  onClose: () => void;
  execution: any;
  getToken: Function;
  companyId: string;
}) => {
  const [preview, setPreview] = useState("");

  const loadPreview = async () => {
    const currentToken = await getToken();
    axios
      .get(
        `${hostname}/sequences/${execution.sequenceId}/executions/${execution.id}/preview`,
        {
          params: { companyId },
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        setPreview(res.data.preview);
      });
  };

  useEffect(() => {
    if (execution) {
      loadPreview();
    }
  }, [execution]);
  if (!execution) return <></>;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">{execution.member.email}</Typography>
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
        {execution.subject}
      </DialogTitle>
      <DialogContent>
        <Box dangerouslySetInnerHTML={{ __html: preview }}></Box>
      </DialogContent>
    </Dialog>
  );
};
