import {
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const SelectEmployeeDialog = ({
  open,
  title,
  content,
  buttonText,
  onSubmit,
  employees,
  onClose,
}: {
  open: boolean;
  title: string;
  content: string;
  buttonText: string;
  employees: Array<any>;
  onSubmit: Function;
  onClose: Function;
}) => {
  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <List>
          {employees.map((employee: any) => (
            <ListItemButton
              key={employee.id}
              onClick={() => onSubmit(employee)}
            >
              <ListItemAvatar>
                {" "}
                <Avatar src={employee.picture}></Avatar>
              </ListItemAvatar>
              <ListItemText>
                {employee.name} ({employee.title})
              </ListItemText>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SelectEmployeeDialog;
