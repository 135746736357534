import {
  Avatar,
  Box,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import moment from "moment";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { hostname, parseError } from "../../utils";

const ChatLog = forwardRef(
  (
    {
      companyId,
      getToken,
      currentUser,
      currentChatId,
      style,
      perPage = 25,
      eventListener,
    }: {
      companyId: string;
      getToken: Function;
      currentUser: any | User;
      currentChatId: any | string;
      style: any;
      perPage: number;
      eventListener: Function;
    },
    ref
  ) => {
    const [chats, setChats] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const observer = useRef();
    const [hasMore, setHasMore] = useState(true);
    const lastChatElementRef = useCallback(
      (node: any) => {
        if (loading) return;
        // @ts-ignore
        if (observer.current) observer.current.disconnect();
        // @ts-ignore
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPage((prevPage) => prevPage + 1);
          }
        });
        // @ts-ignore
        if (node) observer.current.observe(node);
      },
      [loading]
    );

    const getChats = async (append: boolean = false) => {
      setLoading(true);
      const currentToken = await getToken();
      await axios
        .get(`${hostname}/companies/${companyId}/chats`, {
          params: { page, perPage },
          headers: { Authorization: `Bearer ${currentToken}` },
        })
        .then((res) => {
          const newChats = res.data.chats;
          if (!append) {
            setChats(newChats);
          } else {
            setChats((prev: any[]) => [...prev, ...newChats]);
          }
          setHasMore(newChats.length === perPage);
        })
        .catch((err) => {
          eventListener({
            type: "SET_ERROR",
            error: parseError(err),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    useEffect(() => {
      if (page > 1) {
        getChats(true);
      }
    }, [page]);

    useEffect(() => {
      if (currentUser.uid) {
        setChats([]);
        setPage(1);
        getChats();
      }
    }, [currentUser.uid, companyId]);

    useImperativeHandle(ref, () => ({
      refreshChats: async () => {
        setLoading(true);
        const currentToken = await getToken();
        await axios
          .get(`${hostname}/companies/${companyId}/chats`, {
            params: { page: 1, perPage },
            headers: { Authorization: `Bearer ${currentToken}` },
          })
          .then((res) => {
            const newChats = res.data.chats;
            setChats(newChats);
            setHasMore(newChats.length === perPage);
            setPage(1);
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    }));

    const deleteChat = async (chat: any, e: any) => {
      e.stopPropagation();
      e.preventDefault();
      const currentToken = await getToken();
      await axios
        .delete(`${hostname}/chats/${chat.id}`, {
          headers: { Authorization: `Bearer ${currentToken}` },
        })
        .catch((err) => {
          eventListener({
            type: "SET_ERROR",
            error: parseError(err),
          });
        });
      setChats((prev) => prev.filter((c: any) => c.id !== chat.id));
    };

    return (
      <div style={style}>
        <List>
          {!loading && chats && chats.length === 0 && (
            <ListItem>
              <ListItemText sx={{ textAlign: "center" }}>
                No Conversations Found
              </ListItemText>
            </ListItem>
          )}
          {chats.map((chat, idx) => (
            <React.Fragment key={chat.id}>
              <ListItemButton
                ref={idx === chats.length - 1 ? lastChatElementRef : null}
                disabled={chat.id === currentChatId}
                onClick={() =>
                  navigate(`/dashboard/companies/${companyId}/chats/${chat.id}`)
                }
              >
                <ListItemAvatar>
                  <Avatar alt={chat.title} src={chat.picture} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${chat.name} (${chat.title})`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        {moment.unix(chat.ts / 1000).fromNow()}
                      </Typography>

                      <Typography
                        component="span"
                        style={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          lineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          maxHeight: 48,
                        }}
                      >
                        {chat.firstMsg}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItemButton>
              {idx < chats.length - 1 && (
                <Divider variant="inset" component="li" />
              )}
            </React.Fragment>
          ))}
          {loading && hasMore && (
            <ListItem>
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            </ListItem>
          )}
        </List>
      </div>
    );
  }
);

export default ChatLog;
