import { Add, DeleteOutline, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { OPERATORS } from "../../constants";
import { ListConditions } from "../../types";

const Filters = ({
  filters,
  groupId,
  headers,
  updateField,
  updateOperator,
  updateValue,
  removeFilter,
}: {
  filters: Array<any>;
  groupId?: number | null | undefined;
  headers: Array<any>;
  updateField: (value: string, i: number) => void;
  updateOperator: (value: string, i: number) => void;
  updateValue: (e: any, i: number) => void;
  removeFilter: (i: number) => void;
}) => {
  const isValueNeeded = (operator: string) => {
    return !operator.includes("empty");
  };

  return (
    <List sx={{ width: "100%", mt: 2 }}>
      {filters.map((filter: any, i: number) => (
        <ListItem
          key={i}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Select
            fullWidth
            value={filter.field}
            sx={{ mr: 2 }}
            onChange={(e) => updateField(e.target.value, i)}
          >
            {headers.map((header: any, i: number) => (
              <MenuItem key={i} value={header.field}>
                {header.field}
              </MenuItem>
            ))}
          </Select>
          <Select
            fullWidth
            value={filter.operator}
            sx={{ mr: 2 }}
            onChange={(e) => updateOperator(e.target.value, i)}
          >
            {OPERATORS.map((operator) => (
              <MenuItem key={operator.value} value={operator.value}>
                {operator.label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            fullWidth
            value={filter.value}
            sx={{
              mr: 2,
              display: isValueNeeded(filter.operator) ? "" : "none",
            }}
            onChange={(e) => updateValue(e.target.value, i)}
          />
          <IconButton onClick={() => removeFilter(i)}>
            <DeleteOutline color="error" />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};

export default ({
  headers,
  conditions,
  setConditions,
}: {
  headers: Array<any>;
  conditions: ListConditions;
  setConditions: (conditions: ListConditions) => void;
}) => {
  const addGroup = () => {
    setConditions({
      ...conditions,
      groups: [...conditions.groups, { combinator: "AND", filters: [] }],
    });
  };

  const addFilter = () => {
    setConditions({
      ...conditions,
      filters: [
        ...conditions.filters,
        { field: headers[0].field, operator: OPERATORS[0].value, value: "" },
      ],
    });
  };

  const setSubCombinator = (i: number, value: string) => {
    const newGroups = [...conditions.groups];
    newGroups[i] = {
      ...newGroups[i],
      combinator: value,
    };
    setConditions({
      ...conditions,
      groups: newGroups,
    });
  };

  const addSubFilter = (index: number) => {
    const newGroups = [...conditions.groups];
    newGroups[index] = {
      ...newGroups[index],
      filters: [
        ...newGroups[index].filters,
        { field: headers[0].field, operator: OPERATORS[0].value, value: "" },
      ],
    };
    setConditions({
      ...conditions,
      groups: newGroups,
    });
  };

  const updateField = (value: string, i: number) => {
    console.log("updateField");
    const newFilters = [...conditions.filters];
    newFilters[i] = {
      ...newFilters[i],
      field: value,
    };
    setConditions({
      ...conditions,
      filters: newFilters,
    });
  };

  const updateOperator = (value: string, i: number) => {
    console.log("updateOperator");
    const newFilters = [...conditions.filters];
    newFilters[i] = {
      ...newFilters[i],
      operator: value,
    };
    setConditions({
      ...conditions,
      filters: newFilters,
    });
  };

  const updateValue = (value: string, i: number) => {
    const newFilters = [...conditions.filters];
    newFilters[i] = {
      ...newFilters[i],
      value: value,
    };
    setConditions({
      ...conditions,
      filters: newFilters,
    });
  };

  const removeFilter = (i: number) => {
    setConditions({
      ...conditions,
      filters: [
        ...conditions.filters.filter((x: any, index: number) => index !== i),
      ],
    });
  };

  const getFilterCount = () => {
    var filterCount = 0;
    try {
      filterCount = conditions.filters.length;
      filterCount =
        filterCount +
        conditions.groups.reduce((a, x) => a + x.filters.length, 0);
    } catch (e) {
      return filterCount;
    }
    return filterCount;
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Accordion
        sx={{
          backgroundColor: "transparent", // Or remove the background if you want it to be completely transparent
          boxShadow: "none", // Optional: Remove the default shadow
          border: "none", // Optional: Remove the default border}}>
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Run Conditions ({getFilterCount()})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box>
              <Select
                size="small"
                sx={{ mr: 1 }}
                value={conditions.combinator}
                onChange={(e) =>
                  setConditions({ ...conditions, combinator: e.target.value })
                }
              >
                <MenuItem value={"AND"}>AND</MenuItem>
                <MenuItem value={"OR"}>OR</MenuItem>
              </Select>

              <Button
                variant="outlined"
                sx={{ mr: 1 }}
                startIcon={<Add />}
                onClick={addFilter}
              >
                Add filter
              </Button>
            </Box>

            <Box sx={{}}>
              <Filters
                filters={conditions.filters}
                headers={headers}
                updateField={updateField}
                updateOperator={updateOperator}
                updateValue={updateValue}
                removeFilter={removeFilter}
              />
              {/* {conditions.groups.map((group: any, i: number) => (
                <Paper key={i} sx={{ p: 1, mt: 2 }}>
                  <Select
                    size="small"
                    sx={{ mr: 1 }}
                    value={group.combinator}
                    onChange={(e) => setSubCombinator(i, e.target.value)}
                  >
                    <MenuItem value={"AND"}>AND</MenuItem>
                    <MenuItem value={"OR"}>OR</MenuItem>
                  </Select>
                  <Button
                    variant="outlined"
                    sx={{ mr: 1 }}
                    startIcon={<Add />}
                    onClick={(e) => addSubFilter(i)}
                  >
                    Add filter
                  </Button>
                  <IconButton onClick={() => {}}>
                    <DeleteOutline color="error" />
                  </IconButton>
                  <Filters filters={conditions.groups[i].filters} groupId={i}/>
                </Paper>
              ))} */}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
