import { Close, ContactMail, RefreshOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Company, Employee } from "../../types";
import { hostname } from "../../utils";
import SenderProfileEditorDialog from "./SenderProfileEditorDialog";

export default ({
  open,
  onClose,
  companyId,
  eventListener,
  getToken,
  employees,
  company,
}: {
  open: boolean;
  onClose: () => void;
  companyId: string;
  eventListener: Function;
  getToken: Function;
  employees: Employee[];
  company: Company;
}) => {
  const [profiles, setProfiles] = useState<Array<any>>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0);
  const [editorOpen, setEditorOpen] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const refreshProfiles = async () => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/sequences/profiles`, {
        params: { companyId, page: page + 1, pageSize },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setProfiles(res.data.items);
        setTotal(res.data.total);
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const loadProfiles = async () => {
    setLoading(true);
    await refreshProfiles();
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      loadProfiles();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h6"}>Sender Profiles</Typography>
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
          {refreshing && <LinearProgress />}
          {!refreshing && <div style={{ height: 4 }}></div>}
          <Button
            sx={{ m: 1 }}
            variant={"outlined"}
            onClick={() => setEditorOpen(true)}
            startIcon={<ContactMail />}
          >
            Create Profile
          </Button>
          <IconButton
            onClick={() => refreshProfiles()}
            style={{ float: "right", marginRight: 5 }}
          >
            <RefreshOutlined />
          </IconButton>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profiles.map((profile) => (
                  <TableRow key={profile.id}>
                    <TableCell>{profile.name}</TableCell>
                    <TableCell>{profile.email}</TableCell>
                    <TableCell>
                      {profile.created_date
                        ? moment(profile.created_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {profile.updated_date
                        ? moment(profile.updated_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={total}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </DialogContent>
      <SenderProfileEditorDialog
        open={editorOpen}
        onClose={() => {
          setEditorOpen(false);
          refreshProfiles();
        }}
        profile={selectedProfile}
        companyId={companyId}
        getToken={getToken}
        eventListener={eventListener}
        employees={employees}
        company={company}
      />
    </Dialog>
  );
};
