import { User } from "firebase/auth";
import moment from "moment";

export function uniqueId(
  seedDate: number | string | undefined | null = new Date().getTime()
) {
  // desired length of Id
  var idStrLen = 16;
  // always start with a letter -- base 36 makes for a nice shortcut
  var idStr = (Math.floor(Math.random() * 25) + 10).toString(36);
  // add a timestamp in milliseconds (base 36 again) as the base
  idStr += (seedDate || new Date().getTime()).toString(36);
  // similar to above, complete the Id using random, alphanumeric characters
  do {
    idStr += Math.floor(Math.random() * 35).toString(36);
  } while (idStr.length < idStrLen);

  return idStr;
}

export async function convertAudioBlobsToBase64(audioBlobs: Array<Blob>) {
  let mergedBlob = new Blob(audioBlobs, { type: audioBlobs[0].type });
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(mergedBlob);

    reader.onload = function () {
      if (reader.result && typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject();
      }
    };
  });
}

export const estimateTokens = (text: string, method = "average") => {
  // # method can be "average", "words", "chars", "max", "min", defaults to "max"
  // # "average" is the average of words and chars
  // # "words" is the word count divided by 0.75
  // # "chars" is the char count divided by 4
  // # "max" is the max of word and char\
  // # "min" is the min of word and char
  const word_count = text.split(" ").length;
  const char_count = text.length;
  const tokens_count_word_est = word_count / 0.75;
  const tokens_count_char_est = char_count / 4.0;
  var output = 0;
  if (method === "average") {
    output = (tokens_count_word_est + tokens_count_char_est) / 2;
  } else if (method === "words") {
    output = tokens_count_word_est;
  } else if (method === "chars") {
    output = tokens_count_char_est;
  } else if (method == "max") {
    output = Math.max(tokens_count_word_est, tokens_count_char_est);
  } else if (method === "min") {
    output = Math.min(tokens_count_word_est, tokens_count_char_est);
  } else {
    return (tokens_count_word_est + tokens_count_char_est) / 2;
  }
  return Math.round(output);
};

export const hostname =
  window.location.hostname === "localhost"
    ? "http://127.0.0.1:5002"
    : "https://api.parallellabs.app";

export const truncate = (input: string | undefined, maxLength = 40) => {
  if (!input) return "";
  if (input.length > maxLength) {
    return input.substring(0, maxLength) + "...";
  }
  return input;
};

export const trackAffiliate = (user: User) => {
  // @ts-ignore
  if (user && user.email && window.trackdesktrigger) {
    // @ts-ignore
    window.trackdesktrigger(user.email);
  }
};

export const parseError = (
  err: any,
  defaultError: string = "Oops! Something went wrong! Help us improve your experience by sending us issues in Discord."
) => {
  if (err.response && err.response.data && err.response.data.msg) {
    return err.response.data.msg;
  }
  return defaultError;
};

export function countWords(str: string) {
  return str.trim().split(/\s+/).length;
}

export const fileToBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // @ts-ignore
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const whitelabelDomains = ["mercenarymarketingai.netlify.app"];
const darkLogo = require("./img/logo_transparent_dark.png");
const logo = require("./img/logo_transparent.png");

const isWhitelabelDomain = () => {
  return window.location.hostname.includes("mercenarymarketing");
};

export const getLogo = () => {
  if (isWhitelabelDomain()) {
    return require("./img/merc_logo_lg.png");
  } else {
    return require("./img/logo_transparent_dark.png");
  }
};

export const getLogoMode = (mode: string) => {
  if (isWhitelabelDomain()) {
    return require("./img/merc_logo.png");
  } else {
    return mode === "dark" ? darkLogo : logo;
  }
};

export const getInstanceConfig = (mode: string) => {
  if (isWhitelabelDomain()) {
    return {
      title: "Mercenary Marketing AI",
      headTitle: "Mercenary Marketing AI | Train AI on Your Business",
      faviconText: "MM",
      primaryColor: "rgb(22, 155, 232)",
      secondaryColor: "rgb(35, 55, 132)",
      contrastColor: mode === "dark" ? "#fff" : "#000000",
      backgroundColor: "#121212",
      gradiantColor:
        "linear-gradient(91.54deg, rgb(22, 155, 232) -13.45%, #4affe0 103.78%)",
      isMainSite: false,
      menuColor: mode === "dark" ? "#121212" : "rgb(249, 250, 251)",
      paperColor: mode === "dark" ? "#393E46" : "rgb(249, 250, 251)",
      containedTextColor: mode === "dark" ? "#fff" : "#000000",
    };
  } else {
    return {
      title: "Parallel AI",
      headTitle: "Parallel AI | Train AI on Your Business",
      faviconText: "PA",
      primaryColor: mode === "dark" ? "#A3F7BF" : "#29A19C",
      secondaryColor: mode === "dark" ? "#29A19C" : "#A3F7BF",
      contrastColor: mode === "dark" ? "#fff" : "#000000",
      gradiantColor:
        "linear-gradient(91.54deg, #4affe0 -8.45%, #ffc94a 103.78%)",
      backgroundColor: "rgb(26, 44, 53)",
      isMainSite: true,
      menuColor: mode === "dark" ? "rgb(26, 44, 53)" : "rgb(249, 250, 251)",
      paperColor: mode === "dark" ? "#31363F" : "rgb(249, 250, 251)",
      containedTextColor: "#000000",
    };
  }
};

export const formatTimestamp = (timestamp: any) => {
  if (!timestamp) {
    return {
      fullDateTime: "",
      date: "",
      time: "",
      dayOfWeek: "",
      timeZone: "UTC",
      fromNow: "",
      isoString: "",
    };
  }
  // Create moment object from epoch timestamp and set to UTC
  const momentDate = moment.unix(timestamp).utc();

  // Format the date in different ways
  const formattedData = {
    fullDateTime: momentDate.format("MMMM Do YYYY, h:mm:ss a"),
    date: momentDate.format("YYYY-MM-DD"),
    time: momentDate.format("HH:mm:ss.SSS"),
    dayOfWeek: momentDate.format("dddd"),
    timeZone: "UTC",
    fromNow: momentDate.fromNow(), // Adding a human-readable relative time
    isoString: momentDate.toISOString(),
  };

  return formattedData;
};

export const generateFaviconSVG = (text: string, color: string): string => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
      <rect width="32" height="32" rx="6" fill="${color}"/>
      <text
        x="50%"
        y="50%"
        dominant-baseline="middle"
        text-anchor="middle"
        font-family="Arial"
        font-size="16"
        fill="#000000"
      >${text}</text>
    </svg>
  `;

  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export const validateURL = (url: string) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i" // fragment locator
  );

  return !!urlPattern.test(url);
};

export const trimmedString = (string: string, length: number) => {
  return string.length > length
    ? string.substring(0, length - 3) + "..."
    : string;
};

export const formatStep = (step: any) => {
  if (step.aiPersonalized) {
    return trimmedString(
      `#${step.order} ${step.aiPersonalizedInstructions}`,
      40
    );
  } else {
    return trimmedString(`#${step.order} ${step.subject}`, 40);
  }
};
