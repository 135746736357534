import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { hostname } from "../../utils";

const GenerateSequenceDialog = ({
  open,
  onClose,
  onSequence,
  companyId,
  getToken,
}: {
  open: boolean;
  onClose: () => void;
  onSequence: (sequence: any) => void;
  companyId: string;
  getToken: Function;
}) => {
  const [goal, setGoal] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = await getToken();
      const response = await axios.post(
        `${hostname}/sequences/generate`,
        {
          companyId,
          goal,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful response
      if (response.data.sequence) {
        onSequence(response.data.sequence);
      }
    } catch (error) {
      console.error("Error generating sequence:", error);
      // You might want to show an error message to the user here
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Generate Sequence</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="What's your goal?"
            fullWidth
            variant="outlined"
            value={goal}
            onChange={(e) => setGoal(e.target.value)}
            required
            multiline
            rows={4}
            placeholder="Describe the sequence you want to generate..."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || !goal.trim()}
            loading={loading}
          >
            Generate
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GenerateSequenceDialog;
