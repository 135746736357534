import { Close, Delete, RefreshOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatStep, hostname } from "../../utils";
import SequenceMemberAddDialog from "./SequenceMemberAddDialog";

export default ({
  open,
  onClose,
  companyId,
  sequence,
  getToken,
}: {
  open: boolean;
  onClose: () => void;
  companyId: string;
  sequence?: any;
  getToken: Function;
}) => {
  const [members, setMembers] = useState<Array<any>>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [memberAddOpen, setMemberAddOpen] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const refreshMembers = async () => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/sequences/${sequence.id}/members`, {
        params: { companyId, page: page + 1, pageSize },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setMembers(res.data.items);
        setTotal(res.data.total);
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const loadMembers = async () => {
    setLoading(true);
    await refreshMembers();
    setLoading(false);
  };

  const triggerMember = async (member: any) => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/sequences/${sequence.id}/members/${member.id}/trigger`,
        {
          companyId,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .finally(() => {
        refreshMembers();
      });
  };

  const removeMember = async (member: any) => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/sequences/${sequence.id}/members/${member.id}/remove`,
        {
          companyId,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .finally(() => {
        refreshMembers();
      });
  };

  useEffect(() => {
    if (sequence) {
      loadMembers();
    }
  }, [sequence]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h6"}>
            Sequence Members: {sequence?.name}
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
          {refreshing && <LinearProgress />}
          {!refreshing && <div style={{ height: 4 }}></div>}
          <Button
            sx={{ m: 1 }}
            variant={"outlined"}
            onClick={() => setMemberAddOpen(true)}
          >
            Add Member
          </Button>
          <IconButton
            onClick={() => refreshMembers()}
            style={{ float: "right", marginRight: 5 }}
          >
            <RefreshOutlined />
          </IconButton>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Added</TableCell>
                  <TableCell>Step</TableCell>
                  <TableCell>Step Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell>
                      {member.firstName
                        ? member.firstName + " " + member.lastName
                        : "-"}
                    </TableCell>
                    <TableCell>{member.email}</TableCell>
                    <TableCell>
                      {member.created_date
                        ? moment(member.created_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>{formatStep(member.lastStep)}</TableCell>
                    <TableCell>
                      {member.lastStepDate
                        ? moment(member.lastStepDate).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => removeMember(member)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={total}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </DialogContent>
      <SequenceMemberAddDialog
        open={memberAddOpen}
        onClose={() => {
          setMemberAddOpen(false);
          refreshMembers();
        }}
        sequence={sequence}
        getToken={getToken}
        companyId={companyId}
      />
    </Dialog>
  );
};
