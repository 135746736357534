import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { CodeBlock, dracula } from "react-code-blocks";

export default ({
  open,
  onClose,
  listId,
}: {
  open: boolean;
  onClose: () => void;
  listId: string;
}) => {
  const getCurlCommand = (listId: string) => {
    return `curl -X POST \
  https://api.parallellabs.app/lists/${listId}/webhook \
  -H 'Content-Type: application/json' \
  -d '{
    "Email": "john.doe@example.com",
    "First Name": "John",
    "Last Name": "Doe"
}'`;
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Typography>Send HTTP Post to following endpoint:</Typography>
        <CodeBlock
          language={"bash"}
          text={`https://api.parallellabs.app/lists/${listId}/webhook`}
          wrapLines
          codeBlock
          showLineNumbers={false}
          theme={dracula}
        />
        <Typography sx={{ mt: 2 }}>Example cURL command:</Typography>
        <CodeBlock
          language={"bash"}
          text={getCurlCommand(listId)}
          wrapLines
          codeBlock
          showLineNumbers={false}
          theme={dracula}
        />
        <Typography sx={{ mt: 2 }}>
          Field names must match up exactly with column names in list
        </Typography>
        <Typography sx={{ mt: 2 }}>
          To create columns dynamically add: <code>createColumns=1</code> query
          string parameter. To match existing rows and update data, add: <code>matchFields=Email^Website</code> query string.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
