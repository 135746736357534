import { Firestore } from "@firebase/firestore";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import LoadingScreen from "../../components/LoadingScreen";
import { Company } from "../../types";
import { parseError } from "../../utils";
import ChatApi from "../chat/chatApi";

import BusinessIcon from "@mui/icons-material/BusinessOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { hostname } from "../../utils";
import CompanyCreateModal from "./CompanyCreateModal";

const Companies = ({
  db,
  currentUser,
  token,
  getToken,
  eventListener,
  openDialog,
  onNewOrganization,
}: {
  db: Firestore;
  currentUser: any | User;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
  onNewOrganization: Function;
}) => {
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuCompany, setMenuCompany] = useState<Company | null>(null);

  const chatApi = new ChatApi();
  chatApi.init(eventListener, getToken);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    company: Company
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuCompany(company);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuCompany(null);
  };

  const deleteCompany = async (id: string, name: string) => {
    handleMenuClose();
    const currentToken = await getToken();
    openDialog(
      "Delete Company",
      `Are you sure you want to delete company ${name}?`,
      "Confirm",
      () => () => {
        setLoading(true);
        axios
          .delete(`${hostname}/companies/${id}`, {
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          })
          .finally(() => {
            getCompanies();
          });
      }
    );
  };

  const getCompanies = async () => {
    setLoading(true);
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        if (res.data.companies && res.data.companies.length === 0) {
          setModalOpen(true);
        }
        setCompanies(res.data.companies);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    setLoading(false);
  };

  const handleEditCompany = (company: Company) => {
    handleMenuClose();
    setSelectedCompany(company);
    setModalOpen(true);
  };

  const handleOpenCompany = (companyId: string) => {
    handleMenuClose();
    window.location.href = `/dashboard/companies/${companyId}`;
  };

  const toAvatarName = (name: string) => {
    if (name && name.length) {
      var names = name.split(" ");
      var firstCharName = names
        .filter((x) => x)
        .map((x) => x[0])
        .join("")
        .toUpperCase();
      return firstCharName.length > 1
        ? firstCharName.substring(0, 2)
        : firstCharName[0];
    }
  };

  useEffect(() => {
    if (currentUser.uid) {
      getCompanies();
    }
  }, [currentUser]);

  if (loading) {
    return <LoadingScreen open={loading} />;
  }

  return (
    <Box sx={{ pt: 2, p: 4 }}>
      <Typography style={{ fontFamily: '"Cairo", sans-serif' }} variant="h4">
        Companies
        <Tooltip
          style={{ float: "right", marginTop: 10 }}
          title={`Virtual companies are used to train your virtual employees
                  about your business. The AI will be trained as a employee in this
                  virtual company and return answers relevant to the description and
                  industry. Each company will be self contained with no information
                  shared between them. This can be a shadow company of your existing
                  business or a business you want to start.
                  To get started, create a company, and start chatting with your
                  pre-generated employees.`}
        >
          <InfoOutlined />
        </Tooltip>
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        style={{ marginBottom: 30, marginTop: 20 }}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <BusinessIcon style={{ marginRight: 5 }} /> Create Company
      </Button>

      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <strong>Company Name</strong>
              </TableCell>
              <TableCell>
                <strong>Company Type</strong>
              </TableCell>
              <TableCell>
                <strong>Description</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow
                key={company.id}
                hover
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenCompany(company.id)}
              >
                <TableCell>
                  <Avatar>{toAvatarName(company.name)}</Avatar>
                </TableCell>
                <TableCell>{company.name}</TableCell>
                <TableCell>{company.type}</TableCell>
                <TableCell
                  style={{
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {company.description}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMenuOpen(e, company);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => menuCompany && handleOpenCompany(menuCompany.id)}
        >
          Open
        </MenuItem>
        <MenuItem onClick={() => menuCompany && handleEditCompany(menuCompany)}>
          Edit
        </MenuItem>
        <MenuItem
          onClick={() =>
            menuCompany && deleteCompany(menuCompany.id, menuCompany.name)
          }
        >
          Delete
        </MenuItem>
      </Menu>

      <CompanyCreateModal
        open={modalOpen}
        onClose={() => {
          setSelectedCompany(null);
          setModalOpen(false);
          getCompanies();
        }}
        onSuccess={() => {
          onNewOrganization();
          getCompanies();
        }}
        selectedCompany={selectedCompany}
        chatApi={chatApi}
        eventListener={eventListener}
        currentUser={currentUser}
        getToken={getToken}
      />
    </Box>
  );
};

export default Companies;
