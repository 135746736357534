import AddOutlined from "@mui/icons-material/AddOutlined";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import KeyOutlined from "@mui/icons-material/KeyOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { Company } from "../../types";
import { hostname, parseError } from "../../utils";
import SsoWizard from "../settings/SsoWizard";

export default ({
  company,
  companyId,
  getToken,
  currentUser,
  eventListener,
  openDialog,
}: {
  company: Company;
  companyId: string;
  getToken: Function;
  currentUser: any | User;
  eventListener: Function;
  openDialog: Function;
}) => {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyUsers, setCompanyUsers] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("member");
  const [ssoWizardOpen, setSsoWizardOpen] = useState(false);

  const getData = async () => {
    const currentToken = await getToken();
    setLoading(true);
    await axios
      .get(`${hostname}/company_users/${companyId}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompanyUsers(res.data.company_users);
      })
      .catch((err: any) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addCompanyUser = async () => {
    if (!email || !role) return;
    const currentToken = await getToken();
    setModalLoading(true);
    await axios
      .post(
        `${hostname}/company_users`,
        {
          companyId: companyId,
          email: email,
          role: role,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .catch((err: any) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setModalOpen(false);
        setModalLoading(false);
        setEmail("");
        setRole("member");
        getData();
      });
  };

  const deleteCompanyUser = async (id: string, email: string) => {
    const currentToken = await getToken();
    openDialog(
      "Remove Member",
      `Are you sure you want to remove ${email}?`,
      "Confirm",
      () => () => {
        axios
          .delete(`${hostname}/company_users/${id}`, {
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          })
          .finally(() => {
            getData();
          });
      }
    );
  };

  useEffect(() => {
    if (currentUser.uid) {
      getData();
    }
  }, [currentUser.uid, companyId]);

  const isOwner = () => {
    const companyUser = companyUsers.find((x) => x.email === currentUser.email);
    return companyUser && companyUser.role === "owner";
  };

  if (loading) {
    return <LinearProgress sx={{ mt: 2 }} />;
  }

  return (
    <Box style={{ marginTop: 20 }}>
      {isOwner() && (
        <div>
          <Button
            onClick={() => setModalOpen(true)}
            style={{ marginRight: 10 }}
          >
            <AddOutlined style={{ marginRight: 5 }} /> Add Member
          </Button>
          <Button onClick={() => setSsoWizardOpen(true)}>
            <KeyOutlined style={{ marginRight: 5 }} />
            Configure SSO
          </Button>
        </div>
      )}
      <TableContainer component={Paper} variant="outlined" sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyUsers.map((row) => (
              <TableRow
                key={row.email}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  {row.role.substring(0, 1).toUpperCase() +
                    row.role.substring(1)}
                </TableCell>
                <TableCell align="right">
                  {row.role !== "owner" && (
                    <IconButton
                      onClick={() => deleteCompanyUser(row.id, row.email)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Member
          </Typography>
          <Typography style={{ marginTop: 10 }}>
            Adding member gives them access to your companies AI Employees
          </Typography>
          <TextField
            style={{ marginTop: 20 }}
            fullWidth
            type="email"
            value={email}
            label="Email"
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>Role</InputLabel>
            <Select
              label="Role"
              value={role}
              onChange={(e: any) => setRole(e.target.value)}
            >
              <MenuItem value={"member"}>Member</MenuItem>
              <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"owner"}>Owner</MenuItem>
            </Select>
          </FormControl>
          <LoadingButton
            variant="outlined"
            style={{ marginTop: 20 }}
            onClick={() => addCompanyUser()}
            loading={modalLoading}
          >
            Add
          </LoadingButton>
        </DialogContent>
      </Dialog>
      <SsoWizard
        open={ssoWizardOpen}
        onClose={() => setSsoWizardOpen(false)}
        getToken={getToken}
        companyId={companyId}
        eventListener={eventListener}
      />
    </Box>
  );
};
