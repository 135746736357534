import {
  Add,
  ArrowBack,
  CopyAll,
  DeleteOutline,
  ElectricBolt,
  PlayCircle,
  SettingsApplications,
  Stop,
  TextFields,
  UploadFile,
  UploadFileOutlined,
  Webhook,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import {
  GridCellParams,
  GridColumnHeaderParams,
  GridColumnMenu,
  GridRowSelectionModel,
  GridToolbar,
} from "@mui/x-data-grid";
import axios from "axios";
import { User } from "firebase/auth";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingIconButton from "../../components/LoadingIconButton";
import { LIST_ACTION_TYPE_UPDATE_ROWS, LIST_ACTIONS } from "../../constants";
import { Company } from "../../types";
import { hostname, parseError } from "../../utils";
import ActionTable from "./ActionTable";
import AddColumnDialog from "./AddColumnDialog";
import ImportModal from "./ImportModal";
import ListActionDialog from "./ListActionDialog";
import ListColumnTypeDialog, { TYPE_OPTIONS } from "./ListColumnTypeDialog";
import { StyledDataGrid } from "./StyledGrid";
import TemplateEditor from "./TemplateEditor";
import WebhookDialog from "./WebhookDialog";

export default ({
  mode,
  currentUser,
  getToken,
  eventListener,
  companyId,
  company,
  currentPlan,
  instanceId,
  openDialog,
}: {
  mode: string;
  getToken: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
  company: Company;
  currentPlan: string;
  instanceId: string | undefined | null;
  openDialog: Function;
}) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState<Array<any>>([]);
  const [rows, setRows] = useState<Array<any>>([]);
  const [totalRows, setTotalRows] = useState(100);
  const [actions, setActions] = useState<Array<any>>([]);
  const [importOpen, setImportOpen] = useState(false);
  const [researchOpen, setResearchOpen] = useState(false);
  const [actionOptions, setActionOptions] = useState<any | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });
  const [addColumnOpen, setAddColumnOpen] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [actionAnchor, setActionAnchor] = useState<any>(null);
  const [selectedActionId, setSelectedActionId] = useState(null);
  const [importEl, setImportEl] = useState<any>(null);
  const [webhookOpen, setWebhookOpen] = useState(false);
  const [createTemplateOpen, setCreateTemplateOpen] = useState(false);
  const [listActionTitle, setListActionTitle] = useState("");
  const [listActionType, setListActionType] = useState("");
  const paginationRef = useRef(paginationModel);
  const [editHeaderOpen, setEditHeaderOpen] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState<any>(null);

  const originalPoll = (fn: any, end: any, tries: number) => {
    async function checkCondition(curr: number) {
      const stillLoadingList = await fn();
      console.log("result", stillLoadingList);
      if (curr < tries && stillLoadingList && stillLoadingList.length > 0) {
        setTimeout(checkCondition, 3500, curr + 1);
      } else {
        end();
      }
    }
    checkCondition(1);
  };

  const runTableAction = async (
    actionId: any,
    type: string,
    selectedRows?: GridRowSelectionModel,
    forceRun: boolean = false
  ) => {
    console.log("Running table action", actionId, type);
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/lists/${instanceId}/action/run`,
        {
          companyId,
          actionId: actionId,
          type: type,
          selectedRows: selectedRows,
          forceRun,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res) => {
        originalPoll(
          silentGetList,
          () => console.log("completed polling"),
          100
        );
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const stopTableAction = async (actionId: any, actionType: string) => {
    console.log("Running table action", actionId, actionType);
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/lists/${instanceId}/action/stop`,
        {
          companyId,
          actionId,
          actionType,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res) => {
        originalPoll(silentGetList, () => console.log("completed polling"), 50);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const FieldTypeIcon = ({ type }: { type: string }) => {
    const typeObj = TYPE_OPTIONS.find((x) => x.value === type);
    if (typeObj) {
      return typeObj.icon;
    }
    return <TextFields />;
  };

  const getColumns = (res: any) => {
    return res.data.list.headers.map((x: any) => {
      var field: any = {
        field: x.name,
        headerName: x.name,
        editable: true,
        width: 195,
      };
      if (x.type === "action") {
        field["sortable"] = false;
        field["type"] = "action";
        field["editable"] = false;
        field["width"] = 230;
        field["headerId"] = x.id;
        field["loading"] = x.loading;
        field["actionParameters"] = x.actionParameters;
        field["autoRunEnabled"] = x.autoRunEnabled;
        field["conditions"] = x.conditions;
        field["renderHeader"] = (params: GridColumnHeaderParams) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <strong style={{ marginRight: "8px" }}>{x.name}</strong>
            <LoadingIconButton
              loading={x.loading}
              size="small"
              onClick={(event: SyntheticEvent) => {
                setActionAnchor(event.currentTarget);
                setSelectedActionId(x.id);
              }}
            >
              <PlayCircle />
            </LoadingIconButton>
          </Box>
        );
      } else {
        field["header"] = x;
        field["headerId"] = x.id;
        field["renderHeader"] = (params: GridColumnHeaderParams) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FieldTypeIcon type={x.type} />
            <Box sx={{ ml: 1 }}>{x.name}</Box>
          </Box>
        );
        field["renderCell"] = (params: GridCellParams) => {
          if (params.value == null) {
            return null;
          }
          // @ts-ignore
          const cellValue: string = params.value;
          if (x.type === "text") {
            return x.value;
          } else if (x.type === "url") {
            return (
              <a href={cellValue} target="_blank" rel="noopener noreferrer">
                {cellValue}
              </a>
            );
          } else if (x.type === "image") {
            return (
              <img
                height={50}
                width={50}
                style={{ objectFit: "cover" }}
                src={cellValue}
              />
            );
          }
        };
      }
      return field;
    });
  };

  const addRow = async (res: any) => {
    const currentToken = await getToken();
    return axios
      .post(
        `${hostname}/lists/${instanceId}/row`,
        { companyId },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res: any) => {
        if (res.data.row) {
          setRows((oldRows) => [...oldRows, { id: res.data.row.id }]);
        }
      });
  };

  const addColumn = async (name: string) => {
    setAddColumnOpen(false);
    const currentToken = await getToken();
    return axios
      .post(
        `${hostname}/lists/${instanceId}/header`,
        { companyId, name },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res: any) => {
        if (res.data.header) {
          setColumns((oldColumns) => [
            ...oldColumns,
            {
              field: res.data.header.name,
              headerName: res.data.header.name,
              editable: false,
              width: 200,
            },
          ]);
        }
      });
  };

  const isAnyHeaderLoading = (res: any) => {
    return res.data.list.headers.filter((x: any) => x.loading);
  };

  const isAnyActionsLoading = (res: any) => {
    return res.data.list.actions.filter((x: any) => x.loading);
  };

  const silentGetList = async () => {
    const currentToken = await getToken();
    var tmpPgm = { page: 1, pageSize: 50 };
    setPaginationModel((pgm) => {
      tmpPgm = pgm;
      return pgm;
    });
    return axios
      .get(`${hostname}/lists/${instanceId}`, {
        params: {
          companyId,
          page: tmpPgm.page + 1,
          pageSize: tmpPgm.pageSize,
        },
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        setColumns(getColumns(res));
        setRows(res.data.list.rows.items);
        setTotalRows(res.data.list.rows.total);
        setActions(res.data.list.actions);
        return isAnyHeaderLoading(res) + isAnyActionsLoading(res);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  function EditHeaderItem(props: any) {
    const { myCustomHandler, myCustomValue } = props;
    return (
      <MenuItem onClick={myCustomHandler}>
        <ListItemIcon>
          <SettingsApplications fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  function DeleteHeaderItem(props: any) {
    const { myCustomHandler, myCustomValue } = props;
    return (
      <MenuItem onClick={myCustomHandler}>
        <ListItemIcon>
          <DeleteOutline fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  function EditHeaderTypeItem(props: any) {
    const { myCustomHandler, myCustomValue } = props;
    return (
      <MenuItem onClick={myCustomHandler}>
        <ListItemIcon>
          <SettingsApplications fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  function StopHeaderItem(props: any) {
    const { myCustomHandler, myCustomValue, loading } = props;
    return (
      <MenuItem
        sx={{ display: loading ? "flex" : "none" }}
        onClick={myCustomHandler}
      >
        <ListItemIcon>
          <Stop fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  function CustomColumnMenu(props: any) {
    var slots = {};
    if (props.colDef.type === "action") {
      const NoDivider = () => null;
      slots = {
        columnMenuUserItem: EditHeaderItem,
        stopHeaderItem: StopHeaderItem,
        deleteHeader: DeleteHeaderItem,
        columnMenuDivider: NoDivider,
      };
    } else {
      slots = {
        deleteHeader: DeleteHeaderItem,
        editHeaderType: EditHeaderTypeItem,
      };
    }
    return (
      <GridColumnMenu
        {...props}
        slots={slots}
        slotProps={{
          stopHeaderItem: {
            displayOrder: 10,
            loading: props.colDef.loading,
            // Additional props
            myCustomValue: "Stop Action",
            myCustomHandler: () => {
              stopTableAction(
                props.colDef.headerId,
                LIST_ACTION_TYPE_UPDATE_ROWS
              );
            },
          },
          columnMenuUserItem: {
            displayOrder: 11,
            // Additional props
            myCustomValue: "Edit Action",
            myCustomHandler: () => {
              setResearchOpen(true);
              setActionOptions({
                actionId: props.colDef.headerId,
                actionType: LIST_ACTION_TYPE_UPDATE_ROWS,
                conditions: props.colDef.conditions,
                autoRunEnabled: props.colDef.autoRunEnabled,
                actionParameters: { ...props.colDef.actionParameters },
              });
              const actionType =
                props.colDef.actionParameters.actionType || "agent";
              const listAction = LIST_ACTIONS.find(
                (x) => x.type === actionType
              );
              setListActionType(listAction?.type ?? LIST_ACTIONS[0].type);
              setListActionTitle(listAction?.title ?? LIST_ACTIONS[0].title);
            },
          },
          deleteHeader: {
            // set `displayOrder` for the new item
            displayOrder: 12,
            // Additional props
            myCustomValue:
              props.colDef.type === "action"
                ? "Delete Action"
                : "Delete Column",
            myCustomHandler: () => {
              deleteHeader(props.colDef.headerId);
            },
          },
          editHeaderType: {
            displayOrder: 13,
            // Additional props
            myCustomValue: "Edit Type",
            myCustomHandler: () => {
              editHeaderType(props.colDef.header);
            },
          },
        }}
      />
    );
  }

  const editHeaderType = (header: any) => {
    setEditHeaderOpen(true);
    setSelectedHeader(header);
  };

  const deleteHeader = async (headerId: string) => {
    const currentToken = await getToken();
    openDialog(
      "Delete Column",
      `Are you sure you want to delete this column and its data?`,
      "Confirm",
      () => () => {
        axios
          .delete(`${hostname}/lists/${instanceId}/header/${headerId}`, {
            headers: { Authorization: `Bearer ${currentToken}` },
          })
          .finally(() => {
            getList();
          });
      }
    );
  };

  const getList = async (): Promise<boolean> => {
    const currentToken = await getToken();
    setLoading(true);
    await axios
      .get(`${hostname}/lists/${instanceId}`, {
        params: {
          companyId,
          page: paginationRef.current.page + 1,
          pageSize: paginationRef.current.pageSize,
        },
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        setName(res.data.list.name);
        setColumns(getColumns(res));
        setRows(res.data.list.rows.items);
        setTotalRows(res.data.list.rows.total);
        setActions(res.data.list.actions);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
    return true;
  };

  const saveRow = async (row: any) => {
    const currentToken = await getToken();
    return axios.put(
      `${hostname}/lists/${instanceId}/row`,
      { companyId, row },
      {
        headers: { Authorization: `Bearer ${currentToken}` },
      }
    );
  };

  const processRowUpdate = async (updatedRow: any) => {
    await saveRow(updatedRow);
    return updatedRow;
  };

  const handleProcessRowUpdateError = async (event: any) => {
    console.log("handle error", event);
  };

  const createListTemplate = async (name: string) => {
    const currentToken = await getToken();
    await axios.post(
      `${hostname}/lists/${instanceId}/templates`,
      { companyId, name },
      {
        headers: { Authorization: `Bearer ${currentToken}` },
      }
    );
  };

  const deleteSelected = async () => {
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/lists/${instanceId}/delete`,
        { companyId, rowIds: rowSelectionModel },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    await getList();
  };

  const handleBack = () => {
    setName("");
    navigate(-1);
  };

  useEffect(() => {
    paginationRef.current = paginationModel;
    if (instanceId) {
      getList();
    }
  }, [paginationModel, instanceId]);

  if (!instanceId) {
    return <></>;
  }

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <IconButton
          sx={{ mr: 2 }}
          onClick={handleBack}
          color="primary"
          size={"large"}
        >
          <ArrowBack />
        </IconButton>
        <Typography
          variant="h4"
          sx={{ mr: 1, fontFamily: '"Cairo", sans-serif' }}
        >
          🚀 {name ? name : "Loading..."}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }} flex={"1"} alignItems={"end"}>
        <Box sx={{ flexGrow: 1 }} />
        {rowSelectionModel.length !== 0 && (
          <Button onClick={deleteSelected} startIcon={<DeleteOutline />}>
            Delete Selected ({rowSelectionModel.length})
          </Button>
        )}
        <Button
          onClick={(e) => setImportEl(e.target)}
          sx={{ mr: 2 }}
          startIcon={<UploadFile />}
        >
          Import
        </Button>
        <Button
          sx={{ mr: 2 }}
          startIcon={<CopyAll />}
          onClick={() => setCreateTemplateOpen(true)}
        >
          Save as Template
        </Button>
        <Menu
          open={Boolean(importEl)}
          anchorEl={importEl}
          onClose={() => setImportEl(false)}
        >
          <MenuItem onClick={() => setImportOpen(true)}>
            <ListItemIcon>
              <UploadFileOutlined />
            </ListItemIcon>
            <ListItemText>Import CSV</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => setWebhookOpen(true)}>
            <ListItemIcon>
              <Webhook />
            </ListItemIcon>
            <ListItemText>Import from Webhook</ListItemText>
          </MenuItem>
        </Menu>
        <Button sx={{ mr: 2 }} startIcon={<Add />} onClick={addRow}>
          Add record
        </Button>
        <Button
          sx={{ mr: 2 }}
          startIcon={<Add />}
          onClick={() => setAddColumnOpen(true)}
        >
          Add Column
        </Button>
        <Button
          variant="outlined"
          onClick={(e: any) => {
            setAnchorEl(e.currentTarget);
          }}
          startIcon={<ElectricBolt />}
        >
          Table Actions
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={anchorEl !== null}
          onClose={() => setAnchorEl(null)}
        >
          {LIST_ACTIONS.map((action, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setListActionTitle(action.title);
                setListActionType(action.type);
                setActionOptions(action.options);
                setResearchOpen(true);
                setAnchorEl(null);
              }}
            >
              <ListItemText>{action.title}</ListItemText>
            </MenuItem>
          ))}
          <Divider sx={{ display: actions.length === 0 ? "none" : "" }} />
          {actions.map((action, index) => (
            <MenuItem
              key={index}
              onClick={(event) => {
                runTableAction(action.id, "add_rows");
                setAnchorEl(null);
              }}
            >
              Run: {action.name}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Paper
          variant="outlined"
          sx={{ height: "calc(100vh - 220px)", minHeight: 600, width: "100%" }}
        >
          <StyledDataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            pageSizeOptions={[25, 50, 100]}
            rowCount={totalRows}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={(e) => setPaginationModel(e)}
            slots={{
              toolbar: GridToolbar,
              columnMenu: CustomColumnMenu,
            }}
            onCellEditStop={(params: any, event: any, details: any) => {
              console.log("cell edit", params, details);
            }}
            processRowUpdate={(updatedRow: any, originalRow: any) =>
              processRowUpdate(updatedRow)
            }
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Paper>
        <ActionTable
          actions={actions}
          onRunAction={(action) => runTableAction(action.id, "add_rows")}
          onEditAction={(action) => {
            setResearchOpen(true);
            setActionOptions({
              actionId: action.id,
              action: action.type,
              autoRunEnabled: action.autoRunEnabled,
              condtions: action.conditions,
              actionParameters: { ...action.actionParameters },
            });
          }}
          onStopAction={(action: any) => {
            stopTableAction(action.id, action.actionType);
          }}
        />
        <Menu
          anchorEl={actionAnchor}
          open={actionAnchor !== null}
          onClose={() => {
            setActionAnchor(null);
            setSelectedActionId(null);
          }}
        >
          <MenuItem
            onClick={() => {
              runTableAction(selectedActionId, "update_rows");
              setActionAnchor(null);
              setSelectedActionId(null);
            }}
          >
            Run all rows that haven't run or have errors
          </MenuItem>
          <MenuItem
            onClick={() => {
              runTableAction(selectedActionId, "update_rows", undefined, true);
              setActionAnchor(null);
              setSelectedActionId(null);
            }}
            color={"danger"}
          >
            Force run all rows
          </MenuItem>
          {rowSelectionModel.length !== 0 && (
            <MenuItem
              onClick={() => {
                runTableAction(
                  selectedActionId,
                  "update_rows",
                  rowSelectionModel
                );
                setActionAnchor(null);
                setSelectedActionId(null);
              }}
            >
              Run on selected rows ({rowSelectionModel.length})
            </MenuItem>
          )}
        </Menu>
      </Box>
      <ImportModal
        open={importOpen}
        onClose={() => {
          setImportOpen(false);
          originalPoll(
            silentGetList,
            () => console.log("completed polling"),
            100
          );
        }}
        getToken={getToken}
        eventListener={eventListener}
        listId={instanceId}
        companyId={companyId}
      />
      <ListActionDialog
        open={researchOpen}
        onClose={() => {
          setResearchOpen(false);
          getList();
        }}
        title={listActionTitle}
        type={listActionType}
        headers={columns}
        listId={instanceId}
        companyId={companyId}
        getToken={getToken}
        eventListener={eventListener}
        currentPlan={currentPlan}
        options={actionOptions}
      />
      <AddColumnDialog
        open={addColumnOpen}
        onClose={() => setAddColumnOpen(false)}
        onSave={addColumn}
      />
      <WebhookDialog
        open={webhookOpen}
        onClose={() => setWebhookOpen(false)}
        listId={instanceId}
      />
      <TemplateEditor
        open={createTemplateOpen}
        onClose={() => setCreateTemplateOpen(false)}
        onConfirm={createListTemplate}
      />
      <ListColumnTypeDialog
        open={editHeaderOpen}
        onClose={() => {
          setEditHeaderOpen(false);
          setSelectedHeader(null);
          silentGetList();
        }}
        selectedHeader={selectedHeader}
        listId={instanceId}
        companyId={companyId}
        getToken={getToken}
        eventListener={eventListener}
      />
    </Box>
  );
};
