import { InsertPhoto, Link, Notes, TextFields } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { hostname, parseError } from "../../utils";

export const TYPE_OPTIONS = [
  { value: "text", label: "Text Field", icon: <TextFields /> },
  { value: "long_text", label: "Long Text Field", icon: <Notes /> },
  { value: "url", label: "URL", icon: <Link /> },
  { value: "image", label: "Image Field", icon: <InsertPhoto /> },
];

export default ({
  open,
  onClose,
  listId,
  selectedHeader,
  companyId,
  getToken,
  eventListener,
}: {
  open: boolean;
  onClose: () => void;
  selectedHeader: any;
  listId: string;
  companyId: string;
  getToken: Function;
  eventListener: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("text");

  const handleClose = () => {
    setType("text");
    onClose();
  };

  const handleSubmit = async () => {
    if (!type) return;
    setLoading(true);
    const currentToken = await getToken();
    axios
      .put(
        `${hostname}/lists/${listId}/header/${selectedHeader.id}`,
        {
          companyId,
          type,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedHeader) {
      setType(selectedHeader.type);
    }
  }, [selectedHeader]);

  if (!selectedHeader) return <></>;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Edit {selectedHeader.name}</DialogTitle>
      <DialogContent sx={{ pt: 2 }}>
        <Select
          fullWidth
          sx={{ mt: 1 }}
          value={type}
          onChange={(e: any) => setType(e.target.value)}
        >
          {TYPE_OPTIONS.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>{type.icon}</ListItemIcon>
                <ListItemText primary={type.label} />
              </div>
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ marginRight: "8px" }}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
