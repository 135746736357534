import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { hostname } from "../../utils";

export default ({
  actionParameters,
  setActionParameters,
  getToken,
  setValidForm,
  companyId,
}: {
  actionParameters: any;
  setActionParameters: (p: any) => void;
  getToken: Function;
  setValidForm: Function;
  companyId: string;
}) => {
  const [sequences, setSequences] = useState([]);

  const loadData = async () => {
    const currentToken = await getToken();
    axios
      .get(`${hostname}/sequences`, {
        params: { companyId, page: 1, pageSize: 100 },
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        setSequences(res.data.items);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setValidForm(actionParameters.sequenceId);
  }, [actionParameters]);

  return (
    <Box>
      <TextField
        fullWidth
        sx={{ mt: 2 }}
        value={actionParameters.name}
        label="Name"
        onChange={(e) =>
          setActionParameters({ ...actionParameters, name: e.target.value })
        }
      />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Sequence</InputLabel>
        <Select
          label="Sequence"
          value={actionParameters.sequenceId}
          onChange={(e) => {
            setActionParameters({
              ...actionParameters,
              sequenceId: e.target.value,
            });
          }}
        >
          {sequences.map((sequence: any) => (
            <MenuItem key={sequence.id} value={sequence.id}>
              {sequence.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
