import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { CodeBlock, dracula } from "react-code-blocks";

export default ({
  open,
  onClose,
  inbox,
}: {
  open: boolean;
  onClose: () => void;
  inbox: any;
}) => {
  const getCurlCommand = (inboxId: string | null | undefined) => {
    return `forward-email=https://api.parallellabs.app/inboxes/${inbox.id}/webhook
or to send to multiple inboxes:
forward-email=example@gmail.com,https://api.parallellabs.app/inboxes/${inbox.id}/webhook`;
  };

  if (!inbox) return <></>;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Inbox Webhook</DialogTitle>
      <DialogContent>
        <Typography>
          Configure your email domain using https://forwardemail.net/ to send
          webhooks to the below address when new emails are recieved.
        </Typography>
        <Typography sx={{ mt: 1 }}>
          Send HTTP Post to following endpoint:
        </Typography>
        <CodeBlock
          language={"bash"}
          text={`https://api.parallellabs.app/inboxes/${inbox.id}/webhook`}
          wrapLines
          codeBlock
          showLineNumbers={false}
          theme={dracula}
        />
        <Typography sx={{ mt: 2 }}>Example DNS TXT record</Typography>
        <CodeBlock
          language={"bash"}
          text={getCurlCommand(inbox.id)}
          wrapLines
          codeBlock
          showLineNumbers={false}
          theme={dracula}
        />
      </DialogContent>
    </Dialog>
  );
};
