import { Warning } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { hostname, parseError } from "../../utils";
import ListActionAddSequenceForm from "./ListActionAddSequenceForm";
import ListActionAgentForm from "./ListActionAgentForm";
import ListActionConditions from "./ListActionConditions";

const ResearchAssistantDialog = ({
  open,
  onClose,
  headers,
  listId,
  title,
  type,
  companyId,
  getToken,
  eventListener,
  currentPlan,
  options,
}: {
  open: boolean;
  onClose: () => void;
  headers: Array<any>;
  listId: string;
  title: string;
  type: string;
  companyId: string;
  getToken: Function;
  eventListener: Function;
  currentPlan: string;
  options: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [actionId, setActionId] = useState("");
  const [actionType, setActionType] = useState("update_rows");
  const [autoRunEnabled, setAutoRunEnabled] = useState(false);
  const [conditions, setConditions] = useState<any>({
    combinator: "AND",
    filters: [],
    groups: [],
  });
  const [actionParameters, setActionParameters] = useState<any>({});
  const [existingRecord, setExistingRecord] = useState(false);
  const [validForm, setValidForm] = useState(false);

  const handleClose = () => {
    setActionId("");
    setActionParameters({});
    setActionType("update_rows");
    setAutoRunEnabled(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (!validForm) return;
    setLoading(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/lists/${listId}/action`,
        {
          companyId,
          actionParameters,
          actionId,
          actionType,
          autoRunEnabled,
          conditions,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then(() => {
        setActionId("");
        setActionParameters({});
        setActionType("update_rows");
        setAutoRunEnabled(false);
        setConditions({
          combinator: "AND",
          filters: [],
          groups: [],
        });
        setValidForm(false);
        onClose();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (options) {
      setActionId(options.actionId || "");
      setActionType(options.actionType || "update_rows");
      setActionParameters({
        ...options.actionParameters,
      });
      setAutoRunEnabled(options.autoRunEnabled);
      setConditions(
        options.conditions || {
          combinator: "AND",
          filters: [],
          groups: [],
        }
      );
      setExistingRecord(options.actionId !== undefined);
      setValidForm(false);
    }
  }, [options]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ paddingTop: "16px" }}>
        <div>
          <Typography variant="subtitle1">Enable Auto-Run</Typography>
          <Typography variant="body2" style={{ marginTop: "8px" }}>
            {actionType === "add_rows"
              ? "Auto-Run executes this action daily"
              : "Auto-Run executes this action on new rows immediately."}
          </Typography>
          <Switch
            checked={autoRunEnabled}
            onChange={(e) => setAutoRunEnabled(!autoRunEnabled)}
            color="primary"
          />
        </div>

        <ListActionConditions
          headers={headers}
          conditions={conditions}
          setConditions={setConditions}
        />

        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "24px",
          }}
        >
          <Button variant="outlined" startIcon={<FolderOpen />}>
            Browse Templates (4)
          </Button>
          <Button variant="outlined" startIcon={<Save />}>
            Save as Template
          </Button>
        </div> */}
        {type === "add_sequence" && (
          <ListActionAddSequenceForm
            actionParameters={actionParameters}
            setActionParameters={setActionParameters}
            getToken={getToken}
            setValidForm={setValidForm}
            companyId={companyId}
          />
        )}
        {type === "agent" && (
          <ListActionAgentForm
            actionParameters={actionParameters}
            setActionParameters={setActionParameters}
            currentPlan={currentPlan}
            headers={headers}
            setValidForm={setValidForm}
            existingRecord={existingRecord}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ marginRight: "8px" }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled
          sx={{ display: validForm ? "none" : "block" }}
        >
          <Warning fontSize="small" style={{ marginRight: "4px" }} />
          Missing Fields
        </Button>
        <LoadingButton
          sx={{ display: validForm ? "flex" : "none" }}
          loading={loading}
          onClick={handleSubmit}
          variant="outlined"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResearchAssistantDialog;
