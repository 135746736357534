import { ChatOutlined, PersonAdd } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import capture from "../../capture";
import LoadingButton from "../../components/LoadingButton";
import { Company, Employee } from "../../types";
import { hostname, parseError, uniqueId } from "../../utils";
import ChatApi from "../chat/chatApi";

export default ({
  currentUser,
  companyId,
  getToken,
  eventListener,
  openDialog,
  company,
}: {
  currentUser: any | User;
  companyId: string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
  company: Company;
}) => {
  const [employees, setEmployees] = useState<Array<Employee>>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState<Array<string>>(["read-all"]);
  const [newEmployee, setNewEmployee] = useState<Employee>({
    uid: "",
    id: uniqueId(),
    title: "",
    name: "",
    prompt: "",
    companyId: "",
    tags: [],
    picture: "",
  });
  const [modalLoading, setModalLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [photoLoading, setPhotoLoading] = useState(false);
  const chatApi = new ChatApi();
  chatApi.init(eventListener, getToken);

  const getEmployees = async () => {
    setLoading(true);
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies/${companyId}/employees`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setEmployees(res.data.employees);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    setInit(true);
    setLoading(false);
  };

  const saveEmployee = async () => {
    setModalLoading(true);
    const currentToken = await getToken();
    await axios
      .post(`${hostname}/employees/${newEmployee.id}`, newEmployee, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    await getEmployees();
    setModalLoading(false);
    setModalOpen(false);
    setEditMode(false);
    setNewEmployee({
      uid: "",
      id: uniqueId(),
      title: "",
      name: "",
      prompt: "",
      companyId: "",
      tags: [],
      picture: "",
    });
  };

  const createChat = async (employee: Employee) => {
    capture("Created new chat", {});
    const currentToken = await getToken();
    const chatId = uuidv4();
    await axios
      .post(
        `${hostname}/chats`,
        {
          id: chatId,
          companyId: companyId,
          ts: new Date().getTime(),
          employeeId: employee.id,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    navigate("/dashboard/companies/" + companyId + "/chats/" + chatId);
  };

  const createOneEmployee = async () => {
    capture("Create new employee", {});
    setModalLoading(true);
    await chatApi.createEmployees(company, 1, 1, newEmployee.title, () => {});
    await getEmployees();
    setModalLoading(false);
    setModalOpen(false);
  };

  const deleteEmployee = async (employee: Employee) => {
    const currentToken = await getToken();
    openDialog(
      "Delete Employee",
      `Are you sure you want to delete employee ${employee.name}?`,
      "Confirm",
      () => () => {
        setLoading(true);
        axios
          .delete(`${hostname}/employees/${employee.id}`, {
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          })
          .finally(() => {
            getEmployees();
          });
      }
    );
  };

  const updatePhoto = async () => {
    setPhotoLoading(true);
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/employees/${newEmployee.id}/picture`,
        { companyId: companyId },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) =>
        setNewEmployee({ ...newEmployee, picture: res.data.picture })
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    setPhotoLoading(false);
  };

  useEffect(() => {
    if (currentUser.uid) {
      getEmployees();
    }
  }, [currentUser.uid, companyId]);

  if (loading) {
    return <LinearProgress sx={{ mt: 2 }} />;
  }

  return (
    <Box>
      <Button
        style={{ marginTop: 10 }}
        onClick={() => {
          setEditMode(false);
          setNewEmployee({
            uid: "",
            id: uniqueId(),
            title: "",
            name: "",
            prompt: "",
            companyId: "",
            tags: [],
            picture: "",
          });
          setModalOpen(true);
        }}
        startIcon={<PersonAdd />}
      >
        Create Employee
      </Button>
      {employees.length > 0 && (
        <Box style={{ marginTop: 20, marginBottom: 20 }}>
          <Grid container spacing={3}>
            {employees.map((employee) => (
              <Grid item key={employee.id} xs={12} md={3}>
                <Paper
                  variant="outlined"
                  onClick={() => createChat(employee)}
                  style={{
                    padding: 10,
                    cursor: "pointer",
                  }}
                >
                  <Avatar
                    src={employee.picture}
                    style={{ marginBottom: 10, height: 68, width: 68 }}
                  ></Avatar>
                  <Typography
                    variant="h6"
                    style={{
                      overflow: "hidden",
                      height: 28,
                      marginBottom: 3,
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      lineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {employee.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      overflow: "hidden",
                      height: 24,
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      lineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {employee.title}
                  </Typography>
                  <Divider style={{ marginTop: 10, marginBottom: 0 }} />
                  <div style={{ height: 58, overflow: "hidden" }}>
                    <Typography variant="caption">Tags:</Typography>
                    <Stack direction="row" spacing={1}>
                      {(employee.tags || []).map((tag) => (
                        <Chip key={tag} size="small" label={tag} />
                      ))}
                      {!employee.tags ||
                        (employee.tags.length === 0 && (
                          <Typography variant="caption">None</Typography>
                        ))}
                    </Stack>
                  </div>
                  <div
                    style={{
                      height: 80,
                      overflow: "hidden",
                      marginBottom: 10,
                      display: "-webkit-box",
                      WebkitLineClamp: 4,
                      lineClamp: 4,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <Typography variant="body2">{employee.prompt}</Typography>
                  </div>
                  <Button
                    onClick={(e: any) => {
                      e.stopPropagation();
                      createChat(employee);
                    }}
                  >
                    <ChatOutlined sx={{ mr: 1 }} /> Chat
                  </Button>
                  <Button
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setEditMode(true);
                      setNewEmployee(employee);
                      setModalOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={(e: any) => {
                      e.stopPropagation();
                      deleteEmployee(employee);
                    }}
                  >
                    Delete
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          {!editMode && (
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add New Employee
              </Typography>
              <Typography style={{ marginTop: 10 }}>
                Enter a job title and the generator will automatically create a
                name and description for the employee.
              </Typography>
              <TextField
                style={{ marginTop: 20 }}
                fullWidth
                value={newEmployee.title}
                label="Job Title"
                onChange={(e) =>
                  setNewEmployee({ ...newEmployee, title: e.target.value })
                }
              />
              <Autocomplete
                multiple
                freeSolo
                filterSelectedOptions
                style={{ marginTop: 20 }}
                options={tags}
                getOptionLabel={(option: string) => option}
                onChange={(e, v) => setNewEmployee({ ...newEmployee, tags: v })}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" placeholder="Add Tags" />
                )}
              />
              <LoadingButton
                loading={modalLoading}
                variant="outlined"
                style={{ marginTop: 20 }}
                onClick={() => createOneEmployee()}
              >
                Create
              </LoadingButton>
            </div>
          )}
          {editMode && (
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Employee
              </Typography>
              <Avatar
                sx={{ height: 80, width: 80, mt: 2 }}
                src={newEmployee.picture}
              />
              <LoadingButton
                sx={{ mt: 1 }}
                variant="outlined"
                loading={photoLoading}
                onClick={updatePhoto}
              >
                Update Photo (2 credits)
              </LoadingButton>
              <TextField
                style={{ marginTop: 20 }}
                fullWidth
                value={newEmployee.name}
                label="Name"
                onChange={(e: any) => {
                  e.stopPropagation();
                  setNewEmployee({ ...newEmployee, name: e.target.value });
                }}
              />
              <TextField
                style={{ marginTop: 20 }}
                fullWidth
                value={newEmployee.title}
                label="Job Title"
                onChange={(e) =>
                  setNewEmployee({ ...newEmployee, title: e.target.value })
                }
              />
              <TextField
                style={{ marginTop: 20 }}
                fullWidth
                multiline
                minRows={3}
                value={newEmployee.prompt}
                label="Description"
                onChange={(e) =>
                  setNewEmployee({ ...newEmployee, prompt: e.target.value })
                }
              />
              <Autocomplete
                multiple
                freeSolo
                filterSelectedOptions
                style={{ marginTop: 20 }}
                options={tags}
                getOptionLabel={(option: string) => option}
                value={newEmployee.tags}
                onChange={(e, v) => setNewEmployee({ ...newEmployee, tags: v })}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" placeholder="Add Tags" />
                )}
              />
              <LoadingButton
                loading={modalLoading}
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={() => saveEmployee()}
              >
                Save
              </LoadingButton>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
