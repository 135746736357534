import { ContentCopy } from "@mui/icons-material";
import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { supportedLanguages } from "../constants";
import { Message } from "../types";
import { countWords } from "../utils";
import LoadingCursor from "./LoadingCursor";

export default ({
  chat,
  eventListener,
  mode,
  setDialogSelectedMessage,
}: {
  mode: string;
  chat: Message[];
  eventListener: Function;
  setDialogSelectedMessage: (msg: Message) => void;
}) => {
  const theme = useTheme();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const copyContent = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      eventListener({
        type: "SET_INFO",
        info: "Content copied to clipboard",
      });
    } catch (err) {
      eventListener({
        type: "SET_ERROR",
        error: "Error copying to clipboard",
      });
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current && messagesEndRef.current.scrollIntoView) {
      messagesEndRef.current.scrollIntoView({
        block: "end",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const getMessageBorderRadius = (role: String) => {
    return role !== "user" ? "20px 20px 20px 1px" : "20px 20px 1px 20px";
  };

  const getMessageAlign = (role: String) => {
    return role !== "user" ? "left" : "right";
  };

  const getBackgroundColor = (message: Message) => {
    if (message.role === "user") {
      return mode === "dark" ? "#222831" : theme.palette.background.paper;
    } else {
      return mode === "dark"
        ? "#2b323e"
        : alpha(theme.palette.primary.light, 0.05);
    }
  };

  const getTooltipBlock = (message: Message) => {
    if (
      !message.context ||
      !message.context.memories ||
      message.context.memories.length === 0
    ) {
      return;
    }
    return (
      <Typography variant="body1" component="div">
        <Button
          onClick={() => {
            setDialogSelectedMessage(message);
          }}
          size="small"
          style={{ marginLeft: 5, marginRight: 5, verticalAlign: -2 }}
        >
          Context
        </Button>
      </Typography>
    );
  };

  return (
    <Box>
      <List>
        {chat.map((message: Message, index: number) => (
          <ListItem
            key={message.id}
            style={{
              padding: "0px, auto",
            }}
          >
            {getMessageAlign(message.role) === "right" && (
              <div style={{ flex: "1" }}></div>
            )}
            <Card
              style={{
                borderRadius: getMessageBorderRadius(message.role),
                float: getMessageAlign(message.role),
                backgroundColor: getBackgroundColor(message),
              }}
            >
              <CardHeader
                style={{
                  float: getMessageAlign(message.role),
                  width: "100%",
                }}
                avatar={
                  <Avatar
                    alt={message.user}
                    sx={{ width: 30, height: 30 }}
                    src={message.picture}
                  />
                }
                title={
                  <div>
                    {message.user}
                    <IconButton
                      color="primary"
                      style={{
                        float: "right",
                        fontSize: 10,
                        marginLeft: 10,
                      }}
                      onClick={() => copyContent(message.text)}
                    >
                      <ContentCopy />
                    </IconButton>
                  </div>
                }
                subheader={moment(new Date(message.ts)).fromNow()}
              />
              <CardContent>
                {message.text.startsWith("```") && <p>Code Block</p>}
                <br />
                {message.text === "" && (
                  <p style={{ marginTop: 25 }}>
                    <LoadingCursor
                      loading={message.loading}
                      children={message.text}
                    />
                  </p>
                )}
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  children={message.text.trim()}
                  components={{
                    p(props) {
                      const { children, ...rest } = props;
                      // Check if this is the last paragraph
                      const isLastParagraph =
                        props.node &&
                        props.node.position &&
                        props.node.position.end &&
                        props.node.position.end.offset === message.text.length;
                      return isLastParagraph ? (
                        <p {...rest}>
                          <LoadingCursor loading={message.loading}>
                            {children}
                          </LoadingCursor>
                        </p>
                      ) : (
                        <p {...rest}>{children}</p>
                      );
                    },
                    code(props) {
                      const { children, className, node, inline, ...rest } =
                        props;

                      if (inline) {
                        return (
                          <code
                            style={{
                              fontFamily:
                                '"SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace',
                              lineHeight: "normal",
                              background: "rgb(243 244 246",
                              color: "#000000",
                              borderRadius: "4px",
                              fontSize: "85%",
                              padding: "0.25em 0.5em",
                              borderColor: "hsl(0 0% 89.8%)",
                              borderWidth: 1,
                              border: "1px solid #e5e7eb",
                            }}
                          >
                            {children}
                          </code>
                        );
                      }
                      const match = /language-(\w+)/.exec(className || "");
                      var lang = "";
                      if (match && (match[1] == "sh" || match[1] == "bash")) {
                        match[1] = "shell";
                      }
                      if (match && supportedLanguages.includes(match[1])) {
                        lang = match[1];
                      } else {
                        lang = "javascript";
                      }
                      return (
                        <CopyBlock
                          language={lang}
                          text={String(children).replace(/\n$/, "")}
                          showLineNumbers
                          theme={dracula}
                          wrapLines
                          codeBlock
                        />
                      );
                    },
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  {getTooltipBlock(message)}
                  <div style={{ flex: "1" }}></div>
                  <Typography variant="caption" color="textSecondary">
                    {countWords(message.text)} words
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </ListItem>
        ))}
      </List>
      <Box ref={messagesEndRef} />
    </Box>
  );
};
