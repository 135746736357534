import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SourceOutlined from "@mui/icons-material/SourceOutlined";
import {
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Company } from "../types";
import { hostname, parseError } from "../utils";

export default ({
  open,
  getToken,
  company,
  onClick,
  onClose,
  eventListener,
}: {
  open: boolean;
  getToken: Function;
  company: Company;
  onClick: (folder: any) => void;
  onClose: () => void;
  eventListener: Function;
}) => {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState<Array<any>>([]);
  const [openedFolders, setOpenedFolders] = useState<any>({});

  const getFolders = async () => {
    const currentToken = await getToken();
    axios
      .get(`${hostname}/documents/folders`, {
        params: {
          companyId: company.id,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setFolders(res.data.folders);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setInit(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (company.id) {
      getFolders();
    }
  }, [open, company]);

  const getMenuItem = (folder: any, level: number = 0) => {
    var folderName = folder.name;
    if (
      folder.name === "shared" &&
      (folder.path === "/" || folder.path === "")
    ) {
      folderName = company.name + " Shared";
      folder.color = "primary";
    }
    if (!folder.folders) {
      return (
        <ListItemButton
          key={folder.id}
          onClick={() => onClick(folder)}
          style={{ marginLeft: level * 15 }}
        >
          <ListItemIcon color={folder.color ? folder.color : "inherit"}>
            <SourceOutlined color={folder.color ? folder.color : "inherit"} />
          </ListItemIcon>
          <ListItemText primary={folderName} />
        </ListItemButton>
      );
    } else {
      return (
        <React.Fragment key={folder.id}>
          <ListItemButton
            color={folder.color ? folder.color : "inherit"}
            onClick={() => onClick(folder)}
            style={{ marginLeft: level * 15 }}
          >
            <ListItemIcon color={folder.color ? folder.color : "inherit"}>
              <SourceOutlined color={folder.color ? folder.color : "inherit"} />
            </ListItemIcon>
            <ListItemText
              primary={folderName}
              color={folder.color ? folder.color : "inherit"}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setOpenedFolders({
                  ...openedFolders,
                  [folder.id]: !openedFolders[folder.id],
                });
              }}
            >
              {openedFolders[folder.id] ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemButton>
          <Collapse in={openedFolders[folder.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {folder.folders.map((subFolder: any) =>
                getMenuItem(subFolder, level + 1)
              )}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Select Folder</DialogTitle>
      <DialogContent>
        {loading && <div>Loading...</div>}
        {!folders && init && <div>No Results</div>}
        <List>
          <ListItemButton
            onClick={() =>
              onClick({
                name: "",
                path: "/",
              })
            }
          >
            <ListItemIcon>
              <SourceOutlined />
            </ListItemIcon>
            <ListItemText primary={"/"} />
          </ListItemButton>
          {(folders || []).map((folder: any) => getMenuItem(folder))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
