import { Add, Close, Delete, DragHandle } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import LoadingButton from "../../components/LoadingButton";
import { hostname } from "../../utils";

export default ({
  open,
  onClose,
  sequence,
  companyId,
  getToken,
}: {
  open: boolean;
  onClose: () => void;
  sequence: any;
  companyId: string;
  getToken: Function;
}) => {
  const [id, setId] = useState<string | null>(null);
  const [name, setName] = useState("");
  const [steps, setSteps] = useState<Array<any>>([
    {
      id: uuidv4(),
      subject: `New Email 1`,
      content: "",
      daysAfterPrevious: 0,
      order: 1,
    },
  ]);
  const [selectedStep, setSelectedStep] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<any>(false);
  const [integrations, setIntegrations] = useState<Array<any>>([]);
  const [integrationId, setIntegrationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [senderProfileId, setSenderProfileId] = useState("");
  const [selectedProfile, setSelectedProfile] = useState<any>(null);

  const handleAddStep = () => {
    const newStep = {
      id: uuidv4(),
      subject: `New Email ${steps.length + 1}`,
      content: "",
      daysAfterPrevious: 0,
      order: steps.length + 1,
    };
    setSteps([...steps, newStep]);
    setSelectedStep(newStep);
  };

  const getProfiles = async () => {
    const currentToken = await getToken();
    axios
      .get(`${hostname}/sequences/profiles`, {
        params: { companyId, page: 1, pageSize: 100 },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setProfiles(res.data.items);
      });
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(steps);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSteps(items.map((item, index) => ({ ...item, order: index + 1 })));
  };

  const handleSaveStep = (step: any) => {
    const updatedSteps = steps.map((step) =>
      step.id === selectedStep.id ? { ...step, ...selectedStep } : step
    );
    setSelectedStep(step);
    setSteps(updatedSteps);
  };

  const handleDeleteStep = () => {
    const filteredSteps = steps.filter((step) => step.id !== selectedStep.id);
    setSteps(filteredSteps);
    setOpenDeleteDialog(false);
    setSelectedStep(filteredSteps[0] || null);
  };

  const getIntegrations = async () => {
    const currentToken = await getToken();
    return axios
      .get(`${hostname}/integrations`, {
        params: { companyId },
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        setIntegrations(
          res.data.integrations.filter((x: any) => x.type === "aws")
        );
      });
  };

  const onSubmit = async () => {
    console.log({ name: name, steps: steps });
    const currentToken = await getToken();
    setLoading(true);
    return axios
      .post(
        `${hostname}/sequences`,
        {
          id,
          name,
          steps,
          companyId,
          integrationId,
          senderProfileId: senderProfileId,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then(() => {
        handleClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      getIntegrations();
      getProfiles();
    }
  }, [open]);

  useEffect(() => {
    if (sequence) {
      setId(sequence.id);
      setIntegrationId(sequence.integrationId);
      setName(sequence.name);
      setSteps(sequence.steps);
      if (sequence.steps) {
        setSelectedStep(sequence.steps[0]);
      }
      setSenderProfileId(sequence.senderProfileId);
    }
  }, [sequence]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle>
        <TextField
          sx={{ minWidth: "50%", maxWidth: 400 }}
          label="Sequence Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <IconButton sx={{ float: "right" }} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {/* Left Panel - Step List */}
          <Box sx={{ width: 300, mr: 2, pr: 1, borderRight: "1px solid #ddd" }}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Sender Profile</InputLabel>
              <Select
                value={senderProfileId}
                label="Sender Profile"
                onChange={(e: any) => {
                  setSenderProfileId(e.target.value);
                  setSelectedProfile(
                    profiles.find(
                      (profile: any) => profile.id === e.target.value
                    )
                  );
                }}
              >
                {profiles.map((profile: any) => (
                  <MenuItem key={profile.id} value={profile.id}>
                    {profile.name} ({profile.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Send Account</InputLabel>
              <Select
                label="Send Account"
                value={integrationId}
                onChange={(e) => setIntegrationId(e.target.value)}
              >
                {integrations.map((integration) => (
                  <MenuItem key={integration.id} value={integration.id}>
                    {integration.instanceName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              startIcon={<Add />}
              onClick={handleAddStep}
              fullWidth
            >
              Add Step
            </Button>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="steps">
                {(provided: any) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {steps.map((step, index) => (
                      <Draggable
                        key={step.id}
                        draggableId={step.id}
                        index={index}
                      >
                        {(provided: any) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            selected={selectedStep?.id === step.id}
                            onClick={() => setSelectedStep(step)}
                            sx={{
                              mb: 1,
                              borderRadius: 1,
                              cursor: "pointer",
                              transition: "background-color 0.2s",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Box {...provided.dragHandleProps} sx={{ mr: 1 }}>
                                <DragHandle sx={{ cursor: "grab" }} />
                              </Box>
                              <ListItemText
                                primary={step.subject}
                                secondary={`Day ${step.daysAfterPrevious} | Step ${step.order}`}
                              />
                            </Box>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Box>

          {/* Right Panel - Step Editor */}
          {selectedStep && (
            <Box sx={{ flex: 1, pl: 2 }}>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <Typography variant="h6">
                  {selectedStep === null ? "Edit Step" : selectedStep.subject}
                </Typography>
                <Button
                  color="error"
                  startIcon={<Delete />}
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  Delete Step
                </Button>
              </Box>
              <Box>
                <TextField
                  label="Subject"
                  fullWidth
                  value={selectedStep.subject}
                  onChange={(e) =>
                    handleSaveStep({
                      ...selectedStep,
                      subject: e.target.value,
                    })
                  }
                  sx={{
                    mb: 2,
                    display: selectedStep.aiPersonalized ? "none" : "",
                  }}
                />
                <TextField
                  label="Days After Previous"
                  type="number"
                  fullWidth
                  value={selectedStep.daysAfterPrevious}
                  onChange={(e) =>
                    handleSaveStep({
                      ...selectedStep,
                      daysAfterPrevious: e.target.value,
                    })
                  }
                  sx={{
                    mb: 2,
                  }}
                />
                <TextField
                  label="Email Content"
                  multiline
                  rows={10}
                  fullWidth
                  value={selectedStep.content}
                  onChange={(e) =>
                    handleSaveStep({
                      ...selectedStep,
                      content: e.target.value,
                    })
                  }
                  sx={{
                    mb: 2,
                    display: selectedStep.aiPersonalized ? "none" : "",
                  }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedStep.aiPersonalized}
                      onChange={() => {
                        handleSaveStep({
                          ...selectedStep,
                          aiPersonalized: !selectedStep.aiPersonalized,
                        });
                      }}
                    />
                  }
                  label="AI Personalized"
                />
                <FormHelperText sx={{ mb: 2 }}>
                  Generate a unique email for each recipient using guidence
                  instructions.
                </FormHelperText>
                <TextField
                  label="AI Guidence Instructions"
                  multiline
                  rows={10}
                  fullWidth
                  value={selectedStep.aiPersonalizedInstructions}
                  onChange={(e) =>
                    handleSaveStep({
                      ...selectedStep,
                      aiPersonalizedInstructions: e.target.value,
                    })
                  }
                  sx={{
                    mb: 2,
                    display: !selectedStep.aiPersonalized ? "none" : "",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Step</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this email step? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteStep} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <DialogActions>
        <LoadingButton loading={loading} onClick={onSubmit} variant="contained">
          Save Changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
