import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { hostname } from "../../utils";

export default ({
  open,
  onClose,
  execution,
  companyId,
  getToken,
}: {
  open: boolean;
  onClose: () => void;
  execution: any;
  companyId: string;
  getToken: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const [editExection, setEditExecution] = useState<any>({});

  const handleClose = () => {
    setEditExecution({});
    onClose();
  };

  const saveExection = async () => {
    setLoading(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/sequences/${editExection.sequenceId}/executions/${editExection.id}`,
        {
          execution: editExection,
          companyId,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then(() => {
        handleClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (execution) {
      setEditExecution(execution);
    }
  }, [execution]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Message Editor</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          value={editExection.subject}
          onChange={(e) =>
            setEditExecution({ ...editExection, subject: e.target.value })
          }
          label="Subject"
        />
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          value={editExection.content}
          onChange={(e) =>
            setEditExecution({ ...editExection, content: e.target.value })
          }
          multiline
          rows={10}
          label="Body"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          variant={"contained"}
          loading={loading}
          onClick={saveExection}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
