import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import { hostname, parseError } from "../../utils";

export default ({
  open,
  onClose,
  inbox,
  getToken,
  eventListener,
  companyId,
}: {
  open: boolean;
  onClose: Function;
  inbox: any;
  getToken: Function;
  eventListener: Function;
  companyId: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});

  const handleClose = () => {
    onClose();
  };

  const onSave = async () => {
    setLoading(true);
    const token = await getToken();
    axios
      .post(
        `${hostname}/inboxes`,
        { ...formData, companyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        handleClose();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (inbox) {
      setFormData(inbox);
    }
  }, [inbox]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Inbox</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Create a new inbox for your company and setup your domain or subdomain
          (ex: example.com, ai.example.com,sales.example.com) to forward to your
          unique webhook address. This allows you to receive emails from your
          customers and automate your replies. Use https://forwardemail.net/ to
          setup email forwarding for your companies domain.
        </DialogContentText>
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          label="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          label="Domain Name"
          value={formData.domain}
          onChange={(e) => setFormData({ ...formData, domain: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <LoadingButton onClick={onSave} variant={"contained"}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
